import axiosApi from "../../api";
import keycloakService from "../../services/keycloakService";
import * as UsersApi from "../../shared/Api/userManagement";
import swal from "sweetalert";
import { ALERT_ERROR } from "../../shared/constants/constants";
import { ToastAlert, getServerError } from "../../shared/utility";

import * as actionTypes from "./actionTypes";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (
    //keycloak,
    userInfo,
    token,
    isAuthenticated
) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        isAuthenticated: isAuthenticated,
        //keycloak: keycloak,
        userInfo: userInfo,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    localStorage.removeItem("token");
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

//export const auth = (email, password, isSignup) => {
export const auth = (history) => {
    return (dispatch) => {
        dispatch(authStart());
        keycloakService.initKeycloak().then((authenticated) => {
            let isAuthenticated = authenticated;
            if (isAuthenticated === true) {
                let token = localStorage.getItem("token");
                if (token) {
                    localStorage.setItem("token", keycloakService.getToken());
                    let userInfo = {
                        userId: keycloakService.getUserinfo().customerId,
                        clientId: keycloakService.getUserinfo().clientId,
                        name: keycloakService.getUserinfo().name,
                        username:
                            keycloakService.getUserinfo().preferred_username,
                        familyname: keycloakService.getUserinfo().family_name,
                        email: keycloakService.getUserinfo().email,
                        email_verified:
                            keycloakService.getUserinfo().email_verified,
                        facilityId: keycloakService.getUserinfo().facilityId,
                        roles: keycloakService.getUserinfo().resource_access.fms
                            .roles,
                        permission: [],
                        //account : keycloak.tokenParsed.resource_access.account.roles,
                    };
                    axiosApi
                        .getDataRequest(UsersApi.GET_USER_INFO)
                        .then((response) => {
                            if (response.status === 200) {
                                userInfo["permission"] =
                                    response.data.permission;
                            }
                            dispatch(
                                authSuccess(
                                    //keycloak,
                                    userInfo,
                                    token,
                                    isAuthenticated
                                )
                            );
                        })
                        .catch((error) => {
                            //console.error('There was an error!', error);
                            let message = getServerError(error);
                            if (error.response.status === 403) {
                                swal({
                                    title: "Opps!",
                                    text: "You do not have permission to perform User management, Please contact your Administrator for assistance",
                                    icon: "error",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                    button: {
                                        text: "Logout!",
                                        className: "btn-danger swal-btn-hover",
                                        //closeModal: false,
                                    },
                                }).then((value) => {
                                    history.push("/");
                                    keycloakService.doLogout();
                                    localStorage.clear();
                                });
                            } else {
                                //ToastAlert({ type: ALERT_ERROR, msg: message });
                                swal({
                                    title: "Opps!",
                                    text: "We have encountered an error,for security reasons you will not be able to continue logging into the platfrom, Please contact your administrator for assistance.",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                    icon: "warning",
                                    button: {
                                        text: "Logout!",
                                        className: "btn-danger swal-btn-hover",
                                        //closeModal: false,
                                    },
                                }).then((value) => {
                                    history.push("/");
                                    keycloakService.doLogout();
                                    localStorage.clear();
                                });
                            }
                        });
                } else {
                    history.push("/otpverify");
                }
            }
        });
    };
};
