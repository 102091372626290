import * as actionTypes from '../actions/actionTypes';
const initialState = {
    loading: false
};

const applicationReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SHOW_LOADER:
            return { ...state, loading: true };
  
        case actionTypes.HIDE_LOADER:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default applicationReducer;
  
  