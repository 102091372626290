/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
//import {BASE_URL} from './shared/constants/constants';

// Default config options
const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    //baseURL:BASE_URL,
    headers: {
        'content-type':'application/json',
        "Access-Control-Allow-Origin": "*",
    },
};

//Create axios instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export default {
    getDataRequest: (url, params = "") =>
    instance({
        'method':'GET',
        'url':url,
        'params': params,
    }),
    
    postDataRequest: (url, postData) =>
    instance({
        'method': 'POST',
        'url': url,
        'data': postData,
        'headers': {
            'content-type':'application/json'  // override instance defaults
        }
    }),

    putDataRequest: (url, postData) =>
    instance({
        'method': 'PUT',
        'url': url,
        'data': postData
    }),

    deleteDataRequest: (url) =>
    instance({
        'method': 'DELETE',
        'url': url
    })
}