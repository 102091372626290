import Keycloak from "keycloak-js";

const keycloak_Local_Config = {
    url: "https://auth.cetraces.in/auth/",
    realm: "Recity",
    clientId: "fms-localhost",
    resource: "fms-localhost",
    "ssl-required": "external",
    "public-client": true,
    "confidential-port": 0,
};

const keycloak_Dev_Config = {
    url: "https://auth.cetraces.in/auth/",
    realm: "Recity",
    clientId: "fms-frontend",
    resource: "fms-frontend",
    "ssl-required": "external",
    "public-client": true,
    "confidential-port": 0,
};

const keycloak_Qa_Config = {
    url: "https://auth.cetraces.in/auth/",
    realm: "Recity",
    clientId: "fms-frontend-qa",
    resource: "fms-frontend-qa",
    "ssl-required": "external",
    "public-client": true,
    "confidential-port": 0,
};

const keycloak_Prod_Config = {
    url: "https://auth.cetraces.in/auth/",
    realm: "Recity",
    clientId: "fms-frontend-prod",
    resource: "fms-frontend-prod",
    "ssl-required": "external",
    "public-client": true,
    "confidential-port": 0,
};

const keycloak = new Keycloak(
    process.env.REACT_APP_ENV === "development"
        ? keycloak_Dev_Config
        : process.env.REACT_APP_ENV === "stagging"
        ? keycloak_Qa_Config
        : process.env.REACT_APP_ENV === "production"
        ? keycloak_Prod_Config
        : keycloak_Local_Config
);
export default keycloak;
