import React from 'react';
import logoImg from "../../assets/img/logo.png";

import NavigationItem from '../Navigation/NavigationItem/NavigationItem';
const logo = (props) => (
    <div className="logo-box d-none d-lg-block">
        <NavigationItem link="/dashboard" exact>
            <img src={logoImg} className="ml-4 mt-3" width="200" alt="logo"/>
        </NavigationItem>
    </div>
);

export default logo;