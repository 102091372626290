import React from "react";
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {TextField } from '@material-ui/core';
import {AVAILABLE_LANGUAGES} from '../../../shared/constants/constants';
import NavigationItem from "../NavigationItem/NavigationItem";
import { showHideMenu } from "../../../shared/utility"
import menuIcon from "../../../assets/img/menu1.svg";
import bellIcon from "../../../assets/img/bell.svg";
import setting from "../../../assets/sidedrawicn/setting.svg";
import avtar from "../../../assets/sidedrawicn/setting.png";
import logOut from "../../../assets/sidedrawicn/logout.png";
import logoImg from "../../../assets/img/logo.png";

const TopNav = (props) => {
	const { t, i18n } = useTranslation();
	const showHideSidemenu = () => {
		let element = document.getElementById("mainbody");
		if (element.classList.contains("collapseDrawer")) {
			element.classList.remove("collapseDrawer");
		} else {
			element.classList.add("collapseDrawer");
		}
	};

	return (
		<div className="navbar-custom">
			<button className="icon-Button" onClick={showHideSidemenu}>
				<img src={menuIcon} alt="img" />
			</button>
			<img src={logoImg} className="d-block d-lg-none" width="130" alt="logo" />
			<button className="icon-Button ml-auto "></button>
			{showHideMenu(props.userPermission, [
				"1.3","1.3.2","1.1","1.1.2","1.4","1.4.2","1.2","1.2.2","1.7","1.7.2",
				"1.5","1.5.2","1.6","1.6.2","1.8","1.8.2"
			]) !== (-1) ? 
			<Dropdown
				onClick={(e) => document.getElementById("dropdown-basic").click()}
			>
				{props.userInfo.username !== "fms.superadmin" ?
					<Dropdown.Toggle
						id="dropdown-basic"
						style={{ background: "none", border: "none", marginLeft: "6px" }}
					>
						<img src={setting} alt="img" />
					</Dropdown.Toggle> : null
				}

				<Dropdown.Menu className="dropdown-menu-right settings">
					{/* <NavigationItem
						link={"/administration"}
						key={"Administrationmenu"}
						cn="dropdown-item"
						exact
					>
						Administration
					</NavigationItem> */}
					{showHideMenu(props.userPermission, ["1.3","1.3.2"]) !== (-1) ?
						<NavigationItem
							link={"/materials"}
							key={"materialsmenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Material Management')}
					  	</NavigationItem>: null
          			}
					{showHideMenu(props.userPermission, ["1.1","1.1.2"]) !== (-1) ?
						<NavigationItem
							link={"/facility-registration"}
							key={"facilitymenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Facility Registration')}
						</NavigationItem>: null
					}
					{showHideMenu(props.userPermission, ["1.4","1.4.2"]) !== (-1) ?
						<NavigationItem
							link={"/organization"}
							key={"organizationmenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Organization')}
						</NavigationItem>:null
					}
					{/* <NavigationItem
							link={"/admin-client-management"}
							key={"clientmenu"}
							cn="dropdown-item"
							exact
						>Client Management
						</NavigationItem> */}
					{showHideMenu(props.userPermission, ["1.2","1.2.2"]) !== (-1) ?
						<NavigationItem
							link={"/users"}
							key={"usermenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('User Registration')}
						</NavigationItem>:null
					}
					{showHideMenu(props.userPermission, ["1.7","1.7.2"]) !== (-1) ?
						<NavigationItem
							link={"/staff-registration"}
							key={"staffmenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Staff Registration')}
						</NavigationItem>:null
					}
					{showHideMenu(props.userPermission, ["1.5","1.5.2"]) !== (-1) ?
						<NavigationItem
							link={"/admin-expense-setting"}
							key={"expensemenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Expense Setting')}
						</NavigationItem>:null
					}
					{showHideMenu(props.userPermission, ["1.6","1.6.2"]) !== (-1) ?
						<NavigationItem
							link={"/process-settings"}
							key={"processmenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Process Setting')}
						</NavigationItem>:null
					}
					{showHideMenu(props.userPermission, ["1.8","1.8.2"]) !== (-1) ?
						<NavigationItem
							link={"/requisition"}
							key={"requisitionmenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Requisitions')}
						</NavigationItem>:null
					}
				</Dropdown.Menu>
			</Dropdown>
			: null}
			<Dropdown>
				<Dropdown.Toggle
					id="dropdown-basic"
					style={{ background: "none", border: "none" }}
				>
					<img src={bellIcon} alt="img" />
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-menu-right bell">
					<Dropdown.Item href="#/action-1">{t('Notification')}</Dropdown.Item>

					<Dropdown.Item href="#/action-1">{t('Coming soon')}</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<Dropdown
				onClick={(e) => document.getElementById("dropdown-logout").click()}
			>
				<Dropdown.Toggle
					id="dropdown-logout"
					style={{ background: "none", border: "none" }}
				>
					<img src={avtar} alt="img" />
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-menu-right logoutlist">
					{props.userInfo.username !== "fms.superadmin" ?
						<NavigationItem
							link={"/profile"}
							key={"profilemenu"}
							cn="dropdown-item"
							isSideMenu={false}
							exact
						>{t('Profile')}
						</NavigationItem> : null}
					<NavigationItem
						link={"/logout"}
						key={"logoutmenu"}
						cn="dropdown-item"
						isSideMenu={false}
						exact
					> <span>{props.userInfo.name}</span><br />
						<img src={logOut} alt="img" width="15" />
						<span style={{ color: "#f10", marginLeft: "10px" }}> {t('Logout')} </span>
					</NavigationItem>
					{/* <Dropdown.Item href="#/action-1"> 
						<img src={logOut} alt="img" width="15" />
						<span style={{ color: "#f10", marginLeft: "10px" }}> Logout</span>
					</Dropdown.Item> */}
				</Dropdown.Menu>
			</Dropdown>
			{/* Language selection */}
			{/* <TextField
				id="outlined-langs-native"
				style={{width: '60Px'}}
				select
				margin="dense"
				label="" //Select Language
				variant="outlined"
				onChange={(e) => i18n.changeLanguage(e.target.value) }
				SelectProps={{
					native: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
			>
				{AVAILABLE_LANGUAGES.map(element => {
					return (<option value={element} key={`lang-${element}`}>{element}</option>)
				})}
			</TextField> */}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.authenticated,
		userInfo: state.auth.userInfo,
		userPermission: state.auth.userInfo.permission,
	}
}

export default connect(mapStateToProps, null)(TopNav);
