const url_prifix = "api/customer-management/customers";
export const GET_INFORMATION_BY_PINCODE = `api/pincode/`;
export const GET_CUSTOMERS = `${url_prifix}/list`;
export const GET_CUSTOMERS_BY_ID = `${url_prifix}/%s`;
export const ADD_CUSTOMER = `${url_prifix}`;
export const ADD_UPDATE_CUSTOMER_DETAILS = `${url_prifix}/%s/`;
export const DELETE_CUSTOMER = `${url_prifix}/%s`;
export const CHANGE_CUSTOMER_STATUS = `${url_prifix}/change-status/%s`;
export const DELETE_CUSTOMER_BANK = `${url_prifix}/%s/bank/`;
export const DELETE_CUSTOMER_PREMISE = `${url_prifix}/%s/premise/`;
export const GET_MACHINERY = `${url_prifix}/machinery`;
export const GET_STAFFS = `${url_prifix}/staffs`;
export const GET_PROCESS_CUSTOMERS = `${url_prifix}/premise/list`;