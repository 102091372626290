// Material Api's
const url_prifix = "api/material-management";
export const GET_ALL_MATERIALS_LIST = `${url_prifix}/materials`;
export const ADD_MATERIAL = `${url_prifix}/materials`;
export const UPDATE_MATERIAL = `${url_prifix}/materials/`;
export const DELETE_MATERIAL = `${url_prifix}/materials/`;
export const CHNAGE_MATERIAL_STAUTS = `${url_prifix}/materials/change-status/`;

export const MATERIAL_NAME_AS_PER_FACILITY = `${url_prifix}/materials/perfacility`; // Add & list view
export const MATERIAL_NAME_AS_PER_FACILITY_BYID = `${url_prifix}/materials/perfacility/%s`; // delete & update

// Sub Material Api's
//export const GET_ALL_SUBMATERIALS_LIST = 'material/materialList';
//export const GET_ACTVIE_SUBMATERIALS_LIST = 'material/materialList';
export const ADD_SUBMATERIAL = `${url_prifix}/sub-materials`;
export const UPDATE_SUBMATERIAL = `${url_prifix}/sub-materials/`;
export const DELETE_SUBMATERIAL = `${url_prifix}/sub-materials/`;
export const CHNAGE_SUBMATERIAL_STAUTS = `${url_prifix}/sub-materials/change-status/`; 
export const SUBMATERIAL_NAME_AS_PER_FACILITY = `${url_prifix}/sub-materials/perfacility`; // Add & list view
export const SUBMATERIAL_NAME_AS_PER_FACILITY_BYID = `${url_prifix}/sub-materials/perfacility/%s`; // delete & update

// added new route

export const FETCH_MATERIAL_SUBMATERIAL_LIST = `${url_prifix}/materials/custom/list`;