import React, { useState } from 'react'
import { Form, Col } from "react-bootstrap";
// import PropTypes from 'prop-types'
import ButtonCmp from '../../../components/UI/Button/Button';
// import BreadcrumbCmp from '../BreadcrumbCmp';
import placeholderImg from "../../../assets/icons/inward.svg";
import InitialPlaceholder from '../../../components/UI/Input/InitialPlaceholder';
import Modal from "../../../components/UI/Modal/ModelWrapper";
import UploadButtonComponent from '../../../components/UI/Input/UploadButtonComponent';
import { SimpleReactTable } from '../../tables/SimpleTable';
import { useTranslation } from 'react-i18next';
import MaterialWiseSortingTable from './materialwiseSortingTable';
import { randomStringGenrator } from '../../../shared/utility';
// import BreadcrumbCmp from '../../BreadcrumbCmp';
function MaterialWise(props) {
    const { t } = useTranslation();
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const toggleModelpopup = () => {
        setShowCustomerModal(!showCustomerModal);
    }

    const [showTable, setShowTable] = useState(true);
    // const toggleModel = () => {

    // }
    

    return (
        <div >
            
            {/* <BreadcrumbCmp {...props} /> */}
        

            {/* <BreadcrumbCmp {...props} key="Breadcrumb"/> */}


<div style={{ marginTop: 20 }}>
			<MaterialWiseSortingTable
				columns={[
                    {
                        Header: t("Facility Name"),
                        accessor: (d, i) => d.FacilityName
                    },
                    {
                        Header: t("Invoice Number"),
                        accessor: d => d.InvoiceNumber
                    },
                    {
                        Header: t("Date and time"),
                        accessor: d => d.DateTime
                    },
                    {
                        Header: t("Vendor Name"),
                        accessor: d => d.VendorName
                    },
                    {
                        Header: t("Status"),
                        accessor: d => d.Status
                    },
                    {
                        Header: t("Material Type"),
                        accessor: d => d.MaterialType
                    },
                    {
                        Header: t("Sub Material Type"),
                        accessor: d => d.SubMaterialType
                    },
                    {
                        Header: t("Received Quantity(KGs)"),
                        accessor: d => d.ReceivedQuantity
                    },
                    {
                        Header: t("Available Quantity(KGs)"),
                        accessor: d => d.AvailableQuantity
                    },
                    {
                        Header: t("Action"),
                    },
                ]}
				data={[]}
				//subrowAccessor={"premiseDetail"}
				defaultPageSize="10"
				history= {props.history}
				key={randomStringGenrator(4)} 
                shoProcessBtn = {true}
                />
            <Modal
                isPopUpShow={showCustomerModal}
                heading=""
                size='xl'
                toggleModel={toggleModelpopup}
                saveBtnTitle={props.actiontype === t("Update") ? t("Update") : `${t("Add")}`}
            // onSave={onSubmit}
            //  disabled = {props.formIsValid}
            >
            <div>   
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Material type")} </Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Material type")} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Sub material type")} </Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Sub material type")} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Enter Internal quantity (KGs)")} </Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Enter Internal quantity (KGs)")} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Enter dispatch quantity (KGs)")} </Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Enter dispatch quantity (KGs)")} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Count of bags/ Bales")} </Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Count of bags/ Bales")} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Enter price")}</Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Enter price")} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="formBasicEmail" >
                            <Form.Label>{t("Discount quantity (KGs)")}</Form.Label>
                            <Form.Control autocomplete="off" type="text" placeholder={t("Discount quantity (KGs)")} />
                        </Form.Group>

                    </Form.Row>
                </Form></div>
            </Modal>
        </div>
</div>

    )
}

MaterialWise.propTypes = {

}

export default MaterialWise

