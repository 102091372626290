import React,{Suspense}  from 'react';
import ReactDOM from 'react-dom';
// Reducer dependencies
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';

//import i18n (needs to be bundled ;))
import './i18nextConf';

// import reducer
import applicationReducer from './store/reducers/applicationReducer';
import authReducer from './store/reducers/authReducer';
import materialReducer from './store/reducers/materialReducer';
import customerReducer from './store/reducers/customerReducer';
import vendorReducer from './store/reducers/vendorReducer';
import inwardReducer from './store/reducers/inwardReducer';
import processReducer from './store/reducers/processReducer';
import outwardReducer from './store/reducers/outwardReducer';
import transportReducer from './store/reducers/transportReducer';
import facilityReducer from './store/reducers/FacilityReducer';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//  Bootstrap link
//import 'bootstrap/dist/css/bootstrap.min.css';
// Toast
import 'react-toastify/dist/ReactToastify.css';

// Redux devtools 
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

// combine all reducers into rootReducer
const rootReducer = combineReducers({
    material: materialReducer,
	customer:customerReducer,
	vendor:vendorReducer,
	inward:inwardReducer,
	process:processReducer,
	outward: outwardReducer,
	transport: transportReducer,
	facility: facilityReducer,
    auth: authReducer,
	application:applicationReducer
});

// create store
const store = createStore(rootReducer, composeEnhancers(
	applyMiddleware(thunk)
));

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<Suspense fallback="">
				<App/>
			</Suspense>
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
