//Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SET_PINCODE_DATA = 'SET_PINCODE_DATA';

// Material
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL';
export const REQUEST_ADD_MATERIAL = 'REQUEST_ADD_MATERIAL';
export const FETCH_MATERIALS_FAILED = 'FETCH_MATERIALS_FAILED';
export const SET_MATERIALS = 'SET_MATERIALS';

//SubMaterial
export const ADD_SUBMATERIAL = 'ADD_SUBMATERIAL';
export const UPDATE_SUBMATERIAL = 'UPDATE_SUBMATERIAL';
export const REMOVE_SUBMATERIAL = 'REMOVE_SUBMATERIAL';

//Customer
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const ADD_BANK = 'ADD_BANK';
export const UPDATE_BANK = 'UPDATE_BANK';
export const REMOVE_BANK = 'REMOVE_BANK';
export const SET_BANK = 'SET_BANK';

//Transpoters
export const ADD_TRANSPOTER = 'ADD_TRANSPOTER';
export const UPDATE_TRANSPOTER = 'UPDATE_TRANSPOTER';
export const REMOVE_TRANSPOTER = 'REMOVE_TRANSPOTER';
export const FETCH_TRANSPOTER_FAILED = 'FETCH_TRANSPOTER_FAILED';
export const SET_TRANSPOTERS = 'SET_TRANSPOTERS';
export const SET_TRANSPOTERS_DETAILS = 'SET_TRANSPOTERS_DETAILS';
export const SET_TRANSPORT_VEHICLE = 'SET_TRANSPORT_VEHICLE';
export const SET_TRANSPORT_BANK_DETAILS = 'SET_TRANSPORT_BANK_DETAILS';

//Vendor
export const CLEAR_DATA = 'CLEAR_DATA';

export const DELETE_VENDOR_OD_DOC = 'DELETE_VENDOR_OD_DOC';
export const SAVE_VENDOR = 'SAVE_VENDOR';
export const FETCH_VENDORS_FAILED = 'FETCH_VENDOR_FAILED';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_VENDOR_DETAILS = 'SET_VENDOR_DETAILS';
export const SET_MATERIALS_OPTIONS = 'SET_MATERIALS_OPTIONS';
export const DELETE_VENDOR_MATERIAL = 'DELETE_VENDOR_MATERIAL';
export const DELETE_VENDOR_BANK = 'DELETE_VENDOR_BANK';
export const DELETE_VENDOR_PREMISE = 'DELETE_VENDOR_PREMISE';

//INWARD
export const SET_INWARD_LIST = 'SET_INWARD_LIST';
export const SET_INWARD_VIEWDATA = 'SET_INWARD_VIEWDATA';
export const SET_INWARD_INDIRECT_TRANSACTION = 'SET_INWARD_INDIRECT_TRANSACTION';
export const SET_INWARD_MATERIAL_RATING = 'SET_INWARD_MATERIAL_RATING';
export const SET_PROCESS_VENDOR = 'SET_PROCESS_VENDOR';
export const SET_INWARD_MATERIAL = 'SET_INWARD_MATERIAL';
export const SAVE_INWARD = 'SAVE_INWARD';
export const INWARD_INVOICE_POPUP_SHOW = 'INWARD_INVOICE_POPUP_SHOW';
export const INWARD_EXPENSES_POPUP_SHOW = 'INWARD_EXPENSES_POPUP_SHOW';
export const INWARD_RATING_POPUP_SHOW = 'INWARD_RATING_POPUP_SHOW';
export const CHANGE_REDIRECT = 'CHANGE_REDIRECT';
export const SET_EXPENSESTYPES = 'SET_EXPENSESTYPES';
export const SET_EXPENSESTYPESARRAY = 'SET_EXPENSESTYPESARRAY';
export const SET_RATINGDATA = 'SET_RATINGDATA';

//FACILITY
export const SET_FACILITY_DETAILS = 'SET_FACILITY_DETAILS';
export const SET_FACILTIY_EDIT_DETAILS = 'SET_FACILTIY_EDIT_DETAILS';
export const SET_FACILITY_UTILITY_OPTIONS = 'SET_FACILITY_UTILITY_OPTIONS';
export const GET_FACILITY_EQUIPMENT_OPTIONS = 'GET_FACILITY_EQUIPMENT_OPTIONS';

//OUTWARD
export const SET_OUTWARD_LIST = 'SET_OUTWARD_LIST';
export const SET_OUTWARD_VIEWDATA = 'SET_OUTWARD_VIEWDATA';
export const SET_OUTWARD_INDIRECT_TRANSACTION = 'SET_OUTWARD_INDIRECT_TRANSACTION';
export const SET_OUTWARD_UPDATE_RATING = 'SET_OUTWARD_UPDATE_RATING';
export const SET_PROCESS_CUSTOMER = 'SET_PROCESS_CUSTOMER';
export const SET_OUTWARD_MATERIAL = 'SET_OUTWARD_MATERIAL';
export const SET_PROCESS_NUBMERS = 'SET_PROCESS_NUBMERS';
export const SAVE_OUTWARD = 'SAVE_OUTWARD';
export const OUTWARD_INVOICE_POPUP_SHOW = 'OUTWARD_INVOICE_POPUP_SHOW';
export const OUTWARD_EXPENSES_POPUP_SHOW = 'OUTWARD_EXPENSES_POPUP_SHOW';
export const OUTWARD_RATING_POPUP_SHOW = 'OUTWARD_RATING_POPUP_SHOW';
export const CHANGE_OUTWARD_REDIRECT = 'CHANGE_OUTWARD_REDIRECT';
export const SET_OUTWARD_FACILITY_DETAILS = 'SET_OUTWARD_FACILITY_DETAILS';

//PROCESS
export const SET_BALING_LIST = 'SET_BALING_LIST';
export const SET_BALING_DATA = 'SET_BALING_DATA';
export const SET_LUMPING_DATA = 'SET_LUMPING_DATA';
export const SET_FACILTIY_STAFF_DETAILS = 'SET_FACILTIY_STAFF_DETAILS';
export const SET_LUMPING_LIST = 'SET_LUMPING_LIST';
export const SET_LUMPING_PROCESS_INFO = 'SET_LUMPING_PROCESS_INFO';
export const SET_SORTPROCESS_LIST = 'SET_SORTPROCESS_LIST';
export const SET_SORTMATLS_QTY = 'SET_SORTMATLS_QTY';
export const SET_BATCHING_TYPE = 'SET_BATCHING_TYPE';
//

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';