import axios from "axios";
import * as actionTypes from "./actionTypes";
import axiosApi from "../../api";
import { showLoader, hideLoader } from "./applicationAction";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../shared/constants/constants";
//import * as vendorApi from '../../shared/Api/vendorManagement';
//import * as materialsServices from '../../shared/Api/materialManagement';
import * as processApi from "../../shared/Api/processManagement";
//import * as facilityApi from '../../shared/Api/facilityManagement';
import * as materialsServices from "../../shared/Api/materialManagement";
import {
    ToastAlert,
    getServerError,
    putVariableInString,
    dateCustomFormat,
    removeLastComma,
    multiSort,
    //randomStringGenrator,
} from "../../shared/utility";

export const setBatchingType = (batch) => {
    return {
        type: actionTypes.SET_BATCHING_TYPE,
        batchingType: batch === true ? "Materialwise" : "Invoicewise",
    };
};

export const setBalingList = (data, totalBaledQty) => {
    return {
        type: actionTypes.SET_BALING_LIST,
        data: data,
        totalQty: totalBaledQty,
    };
};

export const setBalingdata = (data) => {
    return {
        type: actionTypes.SET_BALING_DATA,
        data: data,
    };
};

export const setLumpingList = (data, totaLumpedQty) => {
    return {
        type: actionTypes.SET_LUMPING_LIST,
        data: data,
        totaLumpedQty: totaLumpedQty,
    };
};

export const setLumpingdata = (data) => {
    return {
        type: actionTypes.SET_LUMPING_DATA,
        data: data,
    };
};

export const setSortMaterialsWithQty = (data) => {
    return {
        type: actionTypes.SET_SORTMATLS_QTY,
        data: data,
    };
};

export const setSortProcessist = (data, toalAvailableQty) => {
    return {
        type: actionTypes.SET_SORTPROCESS_LIST,
        data: data,
        toalAvailableQty: toalAvailableQty,
    };
};

export const setMaterials = (data) => {
    return {
        type: actionTypes.SET_INWARD_MATERIAL,
        data: data,
    };
};

//=============call api=============

const getMaterails = (process) => {
    let url = materialsServices.GET_ALL_MATERIALS_LIST;
    return axiosApi.getDataRequest(url, { isActive: false, process: process });
};

//initialData
export const getInitialDataprocessCreate = (process) => {
    const requestOne = getMaterails(process);
    return (dispatch) => {
        dispatch(showLoader());
        return axios
            .all([requestOne])
            .then(
                axios.spread((...responses) => {
                    // use/access the results
                    dispatch(setMaterials(responses[0].data.data));
                    dispatch(hideLoader());
                })
            )
            .catch((errors) => {
                console.log("error");
                let message = getServerError(errors);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
                // react on errors.
            });
    };
};

//save baling
export const saveBaling = (processType, postdata, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url =
            processType === "Invoicewise"
                ? processApi.SAVE_BALING_INVOICE
                : processApi.SAVE_BALING_MATERIAL;
        axiosApi
            .postDataRequest(url, postdata)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    history.push("/baling");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//update baling
export const updateBaling = (id, processType, postdata, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url =
            processType === "Invoicewise"
                ? putVariableInString(processApi.UPDATE_BALING_INVOICE, id)
                : putVariableInString(processApi.UPDATE_BALING_MATERIAL, id);
        axiosApi
            .putDataRequest(url, postdata)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    history.push("/baling");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// update Baling Status
// export const updateBalingStatus = (status,id, initialFilterData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = putVariableInString(processApi.UPDATE_BALING_STATUS, id)
//         axiosApi.putDataRequest(url, {isActive:status})
//             .then( response => {
//                 let result = response.data;
//                 if(response.status === 200){
//                     ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
//                     dispatch(initBaling(initialFilterData));
//                     dispatch(hideLoader());
//                 }else{
//                     ToastAlert({type:ALERT_ERROR, msg:response.data.message});
//                     dispatch(hideLoader());
//                 }
//             })
//             .catch( error => {
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

//baling list
// export const initBaling = (postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         // const materials = getMaterails();
//         // console.log("Material",materials);
//         let url = processApi.GET_BALING;
//         return axiosApi.postDataRequest(url,postData)
//             .then(response => {
//                 let arrayObj =  [];
//                 let totalBaledQty = 0;
//                 let result = response.data.data;
//                 if (response.status === 200) {
//                     if(result.length > 0){
//                         result.forEach((element) => {
//                             totalBaledQty += (+element.outputQty);
//                             let matlName = ""; let subMatlName = "";
//                             if(element.material.materialNamePerFacility.length > 0){
//                                 let matlNameByFacility = element.material.materialNamePerFacility.filter(
//                                     mf => mf.facilityId === element.facility.facilityId)?.[0];
//                                 if(matlNameByFacility){
//                                     matlName = matlNameByFacility.materialAliasName
//                                 }else{
//                                     matlName = element.material.materialName
//                                 }
//                             }else{
//                                 matlName = element.material.materialName;
//                             }

//                             if(element.subMaterial.subMaterialNamePerFacility.length > 0){
//                                 let subMatlNameByFacility = element.subMaterial.subMaterialNamePerFacility.filter(
//                                     smf => smf.facilityId === element.facility.facilityId)?.[0];
//                                 subMatlName = subMatlNameByFacility
//                                     ? subMatlNameByFacility.subMaterialAliasName
//                                     : element.subMaterial.subMaterialName
//                             }else{
//                                 subMatlName = element.subMaterial.subMaterialName
//                             }

//                             let obj = {
//                                 "balingId" : element.balingId,
//                                 "balingDate" : element.balingDate,
//                                 "baleNumber" : element.baleNumber,
//                                 "inputQty" : element.inputQty,
//                                 "baledQty" : element.outputQty,
//                                 "processLoss" : element.processLoss,
//                                 //"material" : element.material.materialName,
//                                 //"subMaterial" : element.subMaterial.subMaterialName,
//                                 "material" : matlName,
//                                 "subMaterial" : subMatlName,
//                                 "inwardInvoiceNumber" : element.balingInput.map(function (e) {
//                                     return e.inwardInvoiceNumber;}).join(', '),
//                                 "sortingBatchNumber" : element.balingInput.map(function (e) {
//                                     return e.sortingBatchNumber;}).join(', '),
//                                 "facilityId" : element.facility.facilityId,
//                                 "facilityName" : element.facility.name,
//                                 "isActive" :  element.isActive,
//                                 "status" : 'Submitted',
//                                 // "balingStaff" : element.balingStaff,
//                                 // "balingMachinery" : element.balingMachinery
//                             }
//                             arrayObj.push(obj);
//                         });
//                         dispatch(setBalingList(arrayObj, totalBaledQty));
//                         dispatch(hideLoader());
//                     }else{
//                         ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                         dispatch(setBalingList(arrayObj, totalBaledQty));
//                         dispatch(hideLoader());
//                     }
//                     return response;
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                     dispatch(setBalingList(arrayObj, totalBaledQty));
//                     dispatch(hideLoader());
//                 }

//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

// baling by id
export const getBalingById = (id, processType) => {
    return (dispatch) => {
        dispatch(showLoader());
        let isInvoiceWise = processType === "true" ? true : false;
        let url = putVariableInString(processApi.GET_BALING_BY_ID, id);
        axiosApi
            .getDataRequest(url, { invoiceWise: isInvoiceWise })
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200) {
                    if (Object.keys(result).length > 0) {
                        dispatch(setBalingdata(result));
                        dispatch(hideLoader());
                    } else {
                        ToastAlert({
                            type: ALERT_ERROR,
                            msg: response.data.message,
                        });
                        dispatch(hideLoader());
                    }
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//save lumping
export const saveLumping = (processType, postdata, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url =
            processType === "Invoicewise"
                ? processApi.SAVE_LUMPING_INVOICE
                : processApi.SAVE_LUMPING_MATERIAL;
        axiosApi
            .postDataRequest(url, postdata)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    history.push("/lumping");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// update lumping
export const updateLumping = (id, processType, postdata, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url =
            processType === "Invoicewise"
                ? putVariableInString(processApi.UPDATE_LUMPING_INVOICE, id)
                : putVariableInString(processApi.UPDATE_LUMPING_MATERIAL, id);
        axiosApi
            .putDataRequest(url, postdata)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    history.push("/lumping");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// update lumping Status
// export const updateLumpingStatus = (status,id, initialFilterData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = putVariableInString(processApi.UPDATE_LUMPING_STATUS, id)
//         axiosApi.putDataRequest(url, {isActive:status})
//             .then( response => {
//                 let result = response.data;
//                 if(response.status === 200){
//                     ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
//                     dispatch(initLumping(initialFilterData));
//                 }else{
//                     ToastAlert({type:ALERT_ERROR, msg:response.data.message});
//                 }
//                 dispatch(hideLoader());
//             })
//             .catch( error => {
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

//lumping list
// export const initLumping = (postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = processApi.GET_LUMPING;
//         return axiosApi.postDataRequest(url,postData)
//             .then(response => {
//                 let arrayObj =  []; let totaLumpedQty = 0;
//                 let result = response.data.data;
//                 if (response.status === 200) {
//                     if(result.length > 0){
//                         result.forEach((element) => {
//                             totaLumpedQty += (+element.outputQty);
//                             let matlName = ""; let subMatlName = "";
//                             if(element.material.materialNamePerFacility.length > 0){
//                                 let matlNameByFacility = element.material.materialNamePerFacility.filter(
//                                     mf => mf.facilityId === element.facility.facilityId)?.[0];
//                                 if(matlNameByFacility){
//                                     matlName = matlNameByFacility.materialAliasName
//                                 }else{
//                                     matlName = element.material.materialName
//                                 }
//                             }else{
//                                 matlName = element.material.materialName;
//                             }

//                             if(element.subMaterial.subMaterialNamePerFacility.length > 0){
//                                 let subMatlNameByFacility = element.subMaterial.subMaterialNamePerFacility.filter(
//                                     smf => smf.facilityId === element.facility.facilityId)?.[0];
//                                 subMatlName = subMatlNameByFacility
//                                     ? subMatlNameByFacility.subMaterialAliasName
//                                     : element.subMaterial.subMaterialName
//                             }else{
//                                 subMatlName = element.subMaterial.subMaterialName
//                             }

//                             let obj = {
//                                 "lumpingId" : element.lumpingId,
//                                 "lumpingDate" : element.lumpingDate,
//                                 "lumpNumber" : element.lumpNumber,
//                                 "inputQty" : element.inputQty,
//                                 "lumpedQty" : element.outputQty,
//                                 "processLoss" : element.processLoss,
//                                 // "material" : element.material.materialName,
//                                 // "subMaterial" : element.subMaterial.subMaterialName,
//                                 "material" : matlName,
//                                 "subMaterial" : subMatlName,
//                                 "inwardInvoiceNumber" : element.lumpingInput.map(function (e) {
//                                     return e.inwardInvoiceNumber;}).join(', '),
//                                 "sortingBatchNumber" : element.lumpingInput.map(function (e) {
//                                     return e.sortingBatchNumber;}).join(', '),
//                                 "facilityId" : element.facility.facilityId,
//                                 "facilityName" : element.facility.name,
//                                 "isActive" :  element.isActive,
//                                 "status" : 'Submitted',
//                                 // "balingStaff" : element.balingStaff,
//                                 // "balingMachinery" : element.balingMachinery
//                             }
//                             arrayObj.push(obj);
//                         });
//                         dispatch(setLumpingList(arrayObj, totaLumpedQty));
//                         dispatch(hideLoader());
//                     }else{
//                         ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                         dispatch(setLumpingList(arrayObj, totaLumpedQty));
//                         dispatch(hideLoader());
//                     }
//                     return response;
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                     dispatch(setLumpingList(arrayObj, totaLumpedQty));
//                     dispatch(hideLoader());
//                 }
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

//get lumping by id
// export const getLumpingById = (id, processType) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let isInvoiceWise = processType === 'true' ? true : false;
//         let url = putVariableInString(processApi.GET_LUMPING_BY_ID, id);
//         axiosApi.getDataRequest(url,{invoiceWise:isInvoiceWise})
//             .then(response => {
//                 let result = response.data.data;
//                 if (response.status === 200) {
//                     if(Object.keys(result).length > 0){
//                         dispatch(setLumpingdata(result));
//                         dispatch(hideLoader());
//                     }else{
//                         ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                         dispatch(hideLoader());
//                     }
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                     dispatch(hideLoader());
//                 }
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }
//Sorting process list
export const initSortingList = (postData) => {
    return (dispatch) => {
        dispatch(showLoader());
        let arrayObj = [];
        let totalAvailableQty = 0;
        let url = processApi.GET_SORTING_LIST;
        axiosApi
            .postDataRequest(url, postData)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200 && response.data.success === true) {
                    result.forEach((element, index) => {
                        let availableQty = 0;
                        let sortedQuantity = 0;
                        let receivedQuantity = 0;

                        if (
                            element.Status.toLowerCase() !== "draft" &&
                            element.MaterialId !== null
                        ) {
                            let obj = {
                                isChecked: false,
                                //"checkbox_disable": availableQty === 0 ? true :false,
                                SortingId: element.SortingId,
                                FacilityId: element.FacilityId,
                                FacilityName: element.FacilityName,
                                DateTime: dateCustomFormat(
                                    element.DateTime,
                                    "DD MMMM YYYY hh:mm a",
                                    true
                                ),
                                InwardInvoiceNumber:
                                    element.InwardInvoiceNumber,
                                status: element.Status,
                                BatchNumber: element.BatchNumber,
                                isParentRow: true,
                            };
                            if (element.subRows.length > 0) {
                                let materialName = "";
                                let subMaterialName = "";
                                element.subRows.forEach((subEle, i) => {
                                    // calculate avaialble qty
                                    availableQty += +subEle.AvailableQuantity;
                                    sortedQuantity += +subEle.SortedQuantity;
                                    receivedQuantity +=
                                        +subEle.ReceivedQuantity;
                                    totalAvailableQty += availableQty;
                                    // Append Material & sub material name
                                    let matlName = "";
                                    let subMatlName = "";
                                    matlName =
                                        subEle?.MaterialAlias?.length > 0
                                            ? subEle["MaterialAlias"]
                                            : subEle["MaterialType"];
                                    subMatlName =
                                        subEle?.SubMaterialAlias?.length > 0
                                            ? subEle["SubMaterialAlias"]
                                            : subEle["SubMaterialType"];

                                    // if(!materialName.includes(subEle['MaterialType'])){
                                    //     materialName += subEle['MaterialType'] +', ';
                                    // }
                                    if (!materialName.includes(matlName)) {
                                        materialName += matlName + ", ";
                                    }
                                    // if(!subMaterialName.includes(subEle['SubMaterialType'])){
                                    //     subMaterialName += subEle['SubMaterialType'] +', ';
                                    // }
                                    if (
                                        !subMaterialName.includes(subMatlName)
                                    ) {
                                        subMaterialName += subMatlName + ", ";
                                    }
                                    element.subRows[i]["FacilityId"] =
                                        element.FacilityId;
                                    element.subRows[i]["SortingId"] =
                                        element.SortingId;
                                    element.subRows[i]["isChecked"] = false;
                                    element.subRows[i]["checkbox_disable"] =
                                        subEle.AvailableQuantity === 0
                                            ? true
                                            : false;
                                });
                                obj["subRows"] = multiSort(element.subRows, {
                                    //age: 'desc',
                                    MaterialType: "asc",
                                    SubMaterialType: "asc",
                                });
                                obj["ReceivedQuantity"] = receivedQuantity;
                                obj["SortedQuantity"] = sortedQuantity;
                                obj["checkbox_disable"] =
                                    availableQty === 0 ? true : false;
                                obj["AvailableQuantity"] = availableQty;
                                obj["MaterialType"] =
                                    removeLastComma(materialName);
                                obj["SubMaterialType"] =
                                    removeLastComma(subMaterialName);
                            } else {
                                totalAvailableQty += +element.AvailableQuantity;
                                obj["checkbox_disable"] =
                                    +element.AvailableQuantity === 0
                                        ? true
                                        : false;
                                obj["MaterialId"] = element.MaterialId;
                                // obj["MaterialType"] = element.MaterialType?.[0];
                                obj["MaterialType"] =
                                    element.MaterialAlias.length > 0
                                        ? element["MaterialAlias"]
                                        : element.MaterialType?.[0];
                                obj["SubMaterialId"] = element.SubMaterialId;
                                //obj["SubMaterialType"] = element.SubMaterialType?.[0];
                                obj["SubMaterialType"] =
                                    element.SubMaterialAlias.length > 0
                                        ? element["SubMaterialAlias"]
                                        : element.SubMaterialType?.[0];
                                obj["ReceivedQuantity"] =
                                    +element.ReceivedQuantity;
                                obj["AvailableQuantity"] =
                                    +element.AvailableQuantity;
                                obj["SortedQuantity"] = +element.SortedQuantity;
                            }
                            arrayObj.push(obj);
                        }
                    });
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                }
                dispatch(setSortProcessist(arrayObj, totalAvailableQty));
                dispatch(hideLoader());
            })
            .catch((error) => {
                //console.log("error", error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(setSortProcessist(arrayObj, totalAvailableQty));
                dispatch(hideLoader());
            });
    };
};

//Sorting material
export const getSortingMaterialsAndQty = (processName) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = processApi.GET_SORTING_MATERIAL_QTY;
        axiosApi
            .getDataRequest(url, { processType: processName })
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200) {
                    if (result.length > 0) {
                        dispatch(setSortMaterialsWithQty(result));
                        dispatch(hideLoader());
                    } else {
                        ToastAlert({
                            type: ALERT_ERROR,
                            msg: response.data.message,
                        });
                        dispatch(hideLoader());
                    }
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};
