import React from 'react'
//import PropTypes from 'prop-types'
import { Row, Col} from 'react-bootstrap';
import Input from './UI/Input/Input';
import ButtonCmp from './UI/Button/Button';
import ProcessSwitchButton from './UI/Button/ProcessSwitchButton';
import searchIco from '../assets/icons/search.svg';
import filter from '../assets/review/filter.svg';
import { useTranslation } from 'react-i18next';

function ProcessTableFilter(props) {
	const { t } = useTranslation();
    return (
        <div>
            <Row className="commonTableFilter">
				<Col md={3}className="firstFilterSection" style={{paddingLeft: "15px"}}>
					<div className="searchBox position-relative">
						<img src={searchIco} alt="fms"  className="position-absolute  " />
						<div className="searchinput"> 
							<Input
								key="search"
								elementType="input"
								elementConfig={{
									type: 'text',
									placeholder: t('Search here...')
								}}
								value={props.serchValue}
								changed={props.changed} 
							/>				
						</div>				
					</div>
                </Col>
				<Col md={7} className="secondFilterSection">
					<ButtonCmp 
						variant="Button"
						// style={{marginLeft: "-20px"}}
						disabled={false}
						className="selfmargin white mt-2 mr-2 mt-md-0 mlMinus20 Col-md-3" 
						style={{float:"left" }}
						clicked={props.filterModal}
					>	<img src={filter} alt="img" className="pr-3"/>{t("Filter")}
					</ButtonCmp>
					<div style={{display:"flex"}} >
						<ProcessSwitchButton 
							dataOn = "Material wise"
							dataOff = "Invoice wise"
							handleToggle={props.onProcessBatchChange}
							isOn={props.isOn}
						/>	
					</div>
				</Col>
                <Col md={2}  className="thirdFilterSection text-md-right">
					{props.shoProcessBtn ?
                    (<ButtonCmp
                        variant="danger"
                        disabled={false}
                        className="mt-2 mt-md-0"
                        clicked={props.btnClicked}
                        >{props.btnTitle}
                    </ButtonCmp>)
					: null}
                </Col>
			</Row>
        </div>
    )
}

ProcessTableFilter.propTypes = {

}

export default ProcessTableFilter

