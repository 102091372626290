import React from 'react';
import { NavLink } from 'react-router-dom';
import {checkWindowViewPort} from '../../../shared/utility';

//import './NavigationItem.css';
const showHideSidemenu = () => {
    let element = document.getElementById("mainbody");
    if(checkWindowViewPort("(max-width: 991px)")  === true){
        if (element.classList.contains("collapseDrawer")) {
            element.classList.remove("collapseDrawer");
        } else {
            element.classList.add("collapseDrawer");
        }
    }
};

const navigationItem = ( props ) => (
    <>
        <NavLink 
            className={props.cn}
            to={props.link}
            exact={props.exact}
            activeClassName="active"
            onClick={() => {
                if(props.isSideMenu && props.isSideMenu === true){
                    showHideSidemenu()
                }
            }}
        >{props.children}
        </NavLink>
    </>
);

export default navigationItem;