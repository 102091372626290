import React from "react";
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import Logo from "../../Logo/Logo";
import NavigationItem from "../NavigationItem/NavigationItem";
import { useTranslation } from 'react-i18next';
import { AuthenticatedRoutes } from "../../../routes/routes";
import keycloakService from '../../../services/keycloakService';
import {showHideMenu} from "../../../shared/utility";
//import Backdrop from '../../UI/Backdrop/Backdrop';
//import ElementWrapper from '../../../hoc/Wrapper/ElementWrapper';
const DrawerItem = (userPermission,menu, i) => {
	const { t, i18n } = useTranslation();
	return (<Dropdown className="side-menu" key={`p_${i}`}>
		<Dropdown.Toggle>
			<img className="nav-ico" src={menu.icon} alt="img" />{" "}
			{/* <span>{t(menu.label)}</span> */}
			<span>{t(menu.label)}</span>
		</Dropdown.Toggle>
		<Dropdown.Menu>
			{menu.routes.map((childmenu, j) => {
				if (childmenu.sideMenu === true) {
					if(childmenu.permissions.length > 0){
						let isPermissionAllow = showHideMenu(userPermission, childmenu.permissions);
						return isPermissionAllow !== (-1) ? (
							<NavigationItem
								link={childmenu.path}
								key={`child_${i}${j}`}
								isSideMenu={true}
								exact
							>
								{/* {t(childmenu.label)} */}
								{t(childmenu.label)}
							</NavigationItem>
						) : null;
					}else{
						return (
							<NavigationItem
								link={childmenu.path}
								key={`child_${i}${j}`}
								isSideMenu={true}
								exact
							>
								{t(childmenu.label)}
							</NavigationItem>
						);
					}
				} else {
					return null;
				}
			})}
		</Dropdown.Menu>
	</Dropdown>)
}

const NavItem = (menu, i) => {
	const { t, i18n } = useTranslation();
	return (<NavigationItem
		link={menu.path}
		cn={menu.path === "/logout" ? "single-menu l_o" : "single-menu"}
		exact
		key={`p_${i}`}
		isSideMenu={true}
	>
		<img className="nav-ico" src={menu.icon} alt="img" />{" "}
		{/* <span>{menu.label}</span> */}
		<span>{t(menu.label)}</span>
	</NavigationItem>)
}



const sideDrawer = (props) => {
	let menuItems = [];
	menuItems = AuthenticatedRoutes.map((menu, i) => {
		// if(props.userInfo.username === "fms.superadmin"){
		// 	if (menu.path === "/client" && menu.sideMenu === true) {
		// 		return (
		// 			<NavigationItem
		// 			  link={menu.path}
		// 			  cn={menu.path === "/logout" ? "single-menu l_o" : "single-menu"}
		// 			  exact
		// 			  key={`p_${i}`}
		// 			>
		// 			  <img className="nav-ico" src={menu.icon} alt="img" />{" "}
		// 			  <span>{menu.label}</span>
		// 			</NavigationItem>
		// 		);
		// 	}
		// }
		let isPermissionAllow = "";
		isPermissionAllow = showHideMenu(props.userPermission, menu.permissions)
		if (menu.path !== "#/" && menu.sideMenu === true) {
			//if (menu.path !== "/client")
			if(menu.permissions.length > 0){
				return keycloakService.hasRole(menu.roles) && isPermissionAllow !== (-1) ? (
					NavItem(menu,i)
				) : null;
			}else{
				return keycloakService.hasRole(menu.roles) ? 
					NavItem(menu,i)
				: null;
			}
		} else {
			if (menu.routes && menu.sideMenu === true)
				if(menu.permissions.length > 0){
					return keycloakService.hasRole(menu.roles) && isPermissionAllow !== (-1) ? 
						DrawerItem(props.userPermission,menu,i)
					: null;
				}else{
					return keycloakService.hasRole(menu.roles) ?
						DrawerItem(props.userPermission,menu,i)
					: null;
				}
			else
				return null;
		}
	});

	return (
		<aside className="left-side-menu">
			<Logo />
			<div className="sidebar-menu">{menuItems}</div>
		</aside>);
};

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.authenticated,
		userInfo: state.auth.userInfo,
		userPermission: state.auth.userInfo.permission
	}
}
export default connect(mapStateToProps, null)(sideDrawer);
