export const BASE_URL = "http://d539-27-7-237-33.ngrok.io/"; // local
export const ALERT_INFO = "Info";
export const ALERT_WARNING = "Warning";
export const ALERT_ERROR = "Error";
export const ALERT_SUCCESS = "Success";
export const PROCESS_TYPE = "invoice_wise"; //"material_wise";
export const MaterialProcessTypes = [
    "Inward",
    "Sorting",
    "Baling",
    "Lumping",
    "Outward",
];
export const SUBMATERIAL_GST = ["", 0, 5, 18, 28];

export const DOCUMENTID_TYPE = [
    { label: "Aadhaar", value: "Aadhaar", isActive: false },
    { label: "PAN", value: "PAN", isActive: false },
    { label: "Others", value: "Others", isActive: false },
    { label: "Not applicable", value: "Not applicable", isActive: false },
];

export const LEGAL_STATUS = [
    "Private Ltd Co.",
    "Public Limited Co.",
    "Sole Proprietorship",
    "Partnership firm",
    "Limited Liability Partnership",
    "Others",
];

export const CUSTOMER_TYPES = [
    {
        type: "Recycler",
        subtype: ["Fibre", "Granules", "Others"],
    },
    {
        type: "Co-processor",
        subtype: [
            "Cement kilns",
            "Road construction",
            "Pyrolysis",
            "Waste to energy",
            "Granules",
            "Others",
        ],
    },
    {
        type: "Aggregator",
        subtype: [],
    },
    {
        type: "Upcycler",
        subtype: [],
    },
];

export const STAFF_DEPARTMENT_TYPES = [
    {
        type: "Procurement/Delivery",
        subtype: ["Helper", "Driver", "Loader"],
    },
    {
        type: "Baling",
        subtype: ["Machine Operator"],
    },
    {
        type: "Sorting",
        subtype: ["Sorters"],
    },
    {
        type: "Board Making",
        subtype: [
            "Shift In charge",
            "Production Assistants",
            "Production supervisor",
        ],
    },
    {
        type: "Facility Management",
        subtype: [
            "Facility in charge",
            "Facility manager",
            "Operations Manager",
        ],
    },
    {
        type: "Head office",
        subtype: [
            "Project Manager",
            "Executive Director",
            "Finance Coordinator",
            "Finance Incharge",
        ],
    },
];

export const CHECK_CUST_SUBTYPE_INTO = ["Recycler", "Co-processor"];

export const PREMISE_OFFICE_TYPE = [
    "",
    "Head Office",
    "State Office",
    "Regional Office",
    "Zonal Office",
    "City Office",
];

export const BANK_ACCOUNT_TYPES = [
    "Current account",
    "Savings account",
    "Salary account",
];

export const MACHINERY_FACILITY_OFFICE = ["", "DWCC", "MRF"];

export const PREMISE_MACHINERY_UNITS = ["", "KGs", "MTs", "KWs"];

export const MACHINERY_PROCESS = ["", "Sorting", "Baling", "Lumping"];

export const MACHINERY_MACHINE_TYPE = [
    "",
    "generator",
    "conveyor",
    "shredder",
    "sorting",
    "baling",
    "lumping",
];

export const VENDOR_TYPES = [
    "",
    "DWCC",
    "NGO",
    "Bulk Generator",
    "Scrap Shops",
    "Government Organization",
    "MSW (Municipal solid waste)",
    "Aggregator",
    "Recycler",
    "Rag Pickers", // show all other fields
    "Waste Collector", // show all other fields
    "Citizens", // show all other fields,
];

export const PROCESS_STATUS = [
    "",
    "Draft",
    "Submitted",
    "Sorting",
    "Baling",
    "Lumping",
    "Outward",
];

export const ALL_STATES = [
    "ANDAMAN & NICOBAR ISLANDS",
    "ANDHRA PRADESH",
    "ARUNACHAL PRADESH",
    "ASSAM",
    "BIHAR",
    "CHANDIGARH",
    "CHATTISGARH",
    "DADRA & NAGAR HAVELI",
    "DAMAN & DIU",
    "DELHI",
    "GOA",
    "GUJARAT",
    "HARYANA",
    "HIMACHAL PRADESH",
    "JAMMU & KASHMIR",
    "JHARKHAND",
    "KARNATAKA",
    "KERALA",
    "LAKSHADWEEP",
    "MADHYA PRADESH",
    "MAHARASHTRA",
    "MANIPUR",
    "MEGHALAYA",
    "MIZORAM",
    "NAGALAND",
    "ODISHA",
    "PONDICHERRY",
    "PUNJAB",
    "RAJASTHAN",
    "SIKKIM",
    "TAMIL NADU",
    "TRIPURA",
    "UTTAR PRADESH",
    "UTTARAKHAND",
    "WEST BENGAL",
];

export const AVAILABLE_LANGUAGES = ["en", "ar", "fr"];
