import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    materials: [],
    isLoading : true
};

const addMaterial = ( state, action ) => {
    let materialData = [...state.materials, action.data];
    return {
        ...state,
        materials: materialData,
    };
};

const UpdateMaterial = ( state, action ) => {
    let array = [...state.materials];
    let updatedData = action.data;
    let updateId = action.id;
    let index = array.findIndex(obj => obj.materialId === updateId);
    array[index] = updatedData;
    let materialData = array;
    return {
        ...state,
        materials: materialData,
    };
};

const removeMaterial = (state, action) => {
    return state;
};

const setMaterials = (state, action) => {
    return {
        ...state,
        materials: action.materials,
        isLoading: false
    };
};

const fetchMaterialsFailed = (state, action) => {
    return updateObject( state, { error: true, isLoading: false } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADD_MATERIAL: return addMaterial( state, action );
        case actionTypes.UPDATE_MATERIAL: return UpdateMaterial( state, action );
        case actionTypes.REMOVE_MATERIAL: return removeMaterial(state, action);
        case actionTypes.SET_MATERIALS: return setMaterials(state, action);    
        case actionTypes.FETCH_MATERIALS_FAILED: return fetchMaterialsFailed(state, action);
        default: return state;
    }
};

export default reducer;