import * as actionTypes from '../actions/actionTypes';

const intialMatlOptn = [{ 
    label: 'Select Material Type', 
    value: '', isActive: false  
}];

const initialState = {
    materialsArray:[],
    materialOptionsArray: intialMatlOptn,
    balingList:[],
    lumpingList:[],
    balingData:[],
    lumpingData:[],
    sortingMatlQty: [],
    sortingProcessList: [],
    matl_options: [],
    toalAvailableQty: 0,
    totalBaledQty: 0,
    totaLumpedQty:0,
    batchingType:"Invoicewise"
};

const setBatchingType = ( state, action ) => {
    return  {
        ...state, 
        batchingType:action.batchingType,
    };
};

const  setMaterials = ( state, action ) => {
    let new_array = action.data
    .map(m => {
        return {
                label: m.materialName, 
                value: `${m.materialId}`, 
                isActive: m.isActive
            }
        }
    )
    return  {
        ...state, 
        materialsArray:action.data,
        materialOptionsArray: [
            ...intialMatlOptn,
            ...new_array
        ],
        isLoading: false 
    };
};

const setBalingList = ( state, action ) => {
    return  {
        ...state, 
        balingList:action.data,
        totalBaledQty : action.totalQty
    };
};

const setBalingData = ( state, action ) => {
    return  {
        ...state, 
        balingData:action.data
     };
};

const setLumpingList = ( state, action ) => {
    return  {
        ...state, 
        lumpingList:action.data,
        totaLumpedQty: action.totaLumpedQty
     };
};

const setLumpingData = ( state, action ) => {
    return  {
        ...state, 
        lumpingData:action.data
     };
};

const setSortPorcessList = ( state, action ) => {
    return  {
        ...state, 
        sortingProcessList:action.data,
        toalAvailableQty: action.toalAvailableQty
     };
};

const setSortMatlsQtyList = ( state, action ) => {
    let matl_options = [];
    action.data.forEach(element => {
        matl_options.push({
            label: element.materialName, 
            value: element.materialId,
            facilityId: element.facilityId
        });
    });
    return  {
        ...state,
        matl_options:matl_options,
        sortingMatlQty: action.data
    };
};

const processReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_BATCHING_TYPE: return setBatchingType(state, action);
        case actionTypes.SET_BALING_LIST: return setBalingList(state, action);
        case actionTypes.SET_BALING_DATA: return setBalingData(state, action);
        case actionTypes.SET_LUMPING_DATA: return setLumpingData(state, action);
        case actionTypes.SET_LUMPING_LIST: return setLumpingList(state, action);
        
        case actionTypes.SET_SORTPROCESS_LIST: return setSortPorcessList(state, action);
        case actionTypes.SET_SORTMATLS_QTY: return setSortMatlsQtyList(state, action);

        case actionTypes.SET_INWARD_MATERIAL: return setMaterials( state, action );
        
        default: return state;
    }
};

export default processReducer;