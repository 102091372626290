import * as actionTypes from "../actions/actionTypes";
import { objDeepClone } from "../../shared/utility";
const intialVendorOptn = [
    {
        label: "Select Vendor Name",
        value: "",
        isActive: false,
    },
];

const intialExpensesOptn = [
    {
        label: "Select Expense Name",
        value: "",
    },
];

const intialMatlOptn = [
    {
        label: "Select Material Type",
        value: "",
        isActive: false,
    },
];

const intialBillingOptn = [
    {
        label: "Select Billing Address",
        value: "",
        isActive: false,
    },
];

const intialShippingOptn = [
    {
        label: "Select Shipping Address",
        value: "",
        isActive: false,
    },
];

const intialFacilityOptn = [
    {
        label: "Select facility",
        value: "",
    },
];

const intialDriverOptn = [
    {
        label: "Select driver",
        value: "",
        isActive: false,
    },
];

const initialState = {
    vendorArray: [],
    materialsArray: [],
    vendorOptionsArray: objDeepClone(intialVendorOptn),
    materialOptionsArray: objDeepClone(intialMatlOptn),
    billingAddressOptns: objDeepClone(intialBillingOptn),
    shippingAddressOptns: objDeepClone(intialShippingOptn),
    facilityOptions: objDeepClone(intialFacilityOptn),
    driverOptions: objDeepClone(intialDriverOptn),
    invoiceNumberExpenses: "",
    InwardsList: [],
    ExpensesTypes: [],
    ExpensesTypesOptionsArray: intialExpensesOptn,
    InwardId: "",
    RatingData: [],
    ExpansesTypesArray: [],
    inwardViewData: [],
    inwardIndirectTransaction: [],
    inwardMaterialRating: [],
    inwardMatlList: [],
    totalRecievedQty: 0,
    totalInvoicedQty: 0,
    showSendInvoiceModal: false,
    showExpensesModal: false,
    showRatingModal: false,
    isRedirect: false,
};

const toggleInvoiceSenModel = (state, action) => {
    //console.log('Modal', !state.showSendInvoiceModal)
    //    return  {...state,
    //        showSendInvoiceModal:!state.showSendInvoiceModal,
    //        // showExpensesModal: !state.showSendInvoiceModal === false ? true : false
    //        showRatingModal: !state.showSendInvoiceModal === false ? true : false,
    //    };
    let Obj = {};
    if (!state.showSendInvoiceModal === false) {
        Obj.invoiceNumberExpenses = null;
        Obj.InwardId = null;
        Obj.isRedirect = true;
    } else {
        Obj.isRedirect = false;
    }
    Obj.showSendInvoiceModal = !state.showSendInvoiceModal;
    return {
        ...state,
        //  Obj,
        showSendInvoiceModal: !state.showSendInvoiceModal,
        isRedirect: !state.showSendInvoiceModal === false ? true : false,
    };
};

const toggleExpensesModel = (state, action) => {
    return {
        ...state,
        showExpensesModal: !state.showExpensesModal,
        invoiceNumberExpenses:
            state.invoiceNumberExpenses === ""
                ? action?.data?.invoiceNumber
                : state.invoiceNumberExpenses,
        InwardId:
            state.InwardId === "" ? action?.data?.inwardId : state.InwardId,
        //showSendInvoiceModal:!state.showExpensesModal === false ? true : false,
        showRatingModal: !state.showExpensesModal === false ? true : false,
    };
};

const toggleRatingModel = (state, action) => {
    return {
        ...state,
        showSendInvoiceModal: !state.showSendInvoiceModal,
        // showExpensesModal: !state.showSendInvoiceModal === false ? true : false
        showRatingModal: !state.showSendInvoiceModal === false ? true : false,
    };
};

const changeRedirect = (state, action) => {
    return {
        ...state,
        isRedirect: false,
        invoiceNumberExpenses: "",
        InwardId: "",
        RatingData: [],
        ExpansesTypesArray: [],
    };
};

const setInwardList = (state, action) => {
    return {
        ...state,
        InwardsList: action.data,
        totalRecievedQty: action.totalRecievedQty,
        totalInvoicedQty: action.totalInvoicedQty,
    };
};

const setExpensesTypes = (state, action) => {
    let new_array = action.data.map((m) => {
        return {
            label: m.expensesName,
            value: `${m.expensesHeadId}`,
        };
    });
    return {
        ...state,
        ExpensesTypes: action.data,
        ExpensesTypesOptionsArray: [...intialExpensesOptn, ...new_array],
        // isLoading: false
    };
    // return  {
    //     ...state,
    //     ExpensesTypes:action.data
    //  };
};

const setExpansesTypesArray = (state, action) => {
    // console.log("actiondata",action.data)
    return {
        ...state,
        ExpansesTypesArray: action.data,
    };
};

const setRatingData = (state, action) => {
    // console.log('action',action)
    return {
        ...state,
        RatingData: action.data,
    };
};

const setInwardViewData = (state, action) => {
    return {
        ...state,
        inwardViewData: action.data,
    };
};

const setinwardIndirectTransaction = (state, action) => {
    return {
        ...state,
        inwardIndirectTransaction: action.data,
    };
};

const setInwardMaterialRating = (state, action) => {
    return {
        ...state,
        inwardMaterialRating: action.data,
    };
};

const setVendors = (state, action) => {
    let new_array = action.data.map((d) => {
        let isGSTReg = false;
        let vendorHeadPremise = d.vendorPremiseList.filter(
            (p) => p.officeType === "Head Office"
        );
        if (vendorHeadPremise.length > 0) {
            isGSTReg = vendorHeadPremise[0].gstFlag;
        } else if (d.vendorOtherDetails.length > 0) {
            isGSTReg = d.vendorOtherDetails[0]["gstRegistered"];
        }
        return {
            label: d.vendorName,
            value: `${d.vendorId}`,
            isActive: d.isActive,
            isGstReg: isGSTReg,
            vtype: d.vendorType.type,
        };
    });
    return {
        ...state,
        vendorArray: action.data,
        vendorOptionsArray: [...intialVendorOptn, ...new_array],
        isLoading: false,
    };
};

const setMaterials = (state, action) => {
    let new_array = action.data.map((m) => {
        return {
            label: m.materialName,
            value: `${m.materialId}`,
            isActive: m.isActive,
        };
    });
    return {
        ...state,
        materialsArray: action.data,
        materialOptionsArray: [...intialMatlOptn, ...new_array],
        isLoading: false,
    };
};

const inwardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_REDIRECT:
            return changeRedirect(state, action);

        case actionTypes.INWARD_INVOICE_POPUP_SHOW:
            return toggleInvoiceSenModel(state, action);
        case actionTypes.INWARD_EXPENSES_POPUP_SHOW:
            return toggleExpensesModel(state, action);
        case actionTypes.INWARD_RATING_POPUP_SHOW:
            return toggleRatingModel(state, action);

        case actionTypes.SET_INWARD_LIST:
            return setInwardList(state, action);
        case actionTypes.SET_INWARD_VIEWDATA:
            return setInwardViewData(state, action);
        case actionTypes.SET_INWARD_INDIRECT_TRANSACTION:
            return setinwardIndirectTransaction(state, action);
        case actionTypes.SET_INWARD_MATERIAL_RATING:
            return setInwardMaterialRating(state, action);

        case actionTypes.SET_PROCESS_VENDOR:
            return setVendors(state, action);
        case actionTypes.SET_INWARD_MATERIAL:
            return setMaterials(state, action);

        case actionTypes.SET_EXPENSESTYPES:
            return setExpensesTypes(state, action);
        case actionTypes.SET_EXPENSESTYPESARRAY:
            return setExpansesTypesArray(state, action);

        case actionTypes.SET_RATINGDATA:
            return setRatingData(state, action);

        default:
            return state;
    }
};

export default inwardReducer;
