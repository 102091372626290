import React from 'react';
import PropTypes from 'prop-types';
import { Button} from "react-bootstrap";

function ButtonElement(props) {
    return (
        <Button
            variant={props.variant}
            disabled={props.disabled}
            className={props.className}
            onClick={props.clicked}
            style={props.style ? props.style : null }
        >
            {props.children}
        </Button>
    )
}

ButtonElement.propTypes = {
    variant: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    clicked: PropTypes.func.isRequired
}

export default ButtonElement;

