import * as actionTypes from '../actions/actionTypes';
//import {objDeepClone} from '../../shared/utility';

const initialState = {
    transportDetailsArray: [],
    vehicleDetailsArray: [],
    bankDetailsArray: [],
    transportOptionArray: [{ 
        label: 'Select transport', 
        value: '',
    }],
    vehicleTypeOption: [{ 
        label: 'Select vehicle type', 
        value: '',
    }],
};

const setTransportDetails = ( state, action ) => {
    let transportOptionArray = [{ 
        label: 'Select transport', 
        value: '',
    }];
    if(action.data.length > 0){
        action.data.forEach(element => {
            transportOptionArray.push({
                label: element.name,
                value: element.transportId
            })
        });
    }
    return {
        ...state,
        transportDetailsArray: action.data,
        transportOptionArray: transportOptionArray
    };
};

const setVehicleDetails = ( state, action ) => {
    let vehicleTypeOption = [{ 
        label: 'Select vehicle type', 
        value: '',
    }];
    if(action.data.length > 0){
        action.data.forEach(element => {
            vehicleTypeOption.push({
                label: element.vehicleType,
                value: element.vehicleTypeId
            })
        });
    }
    return {
        ...state,
        vehicleDetailsArray: action.data,
        vehicleTypeOption: vehicleTypeOption
    };
};

const setTransportBankDetails = ( state, action ) => {
    return {
        ...state,
        bankDetailsArray: action.data,
    };
};

const transportReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_TRANSPOTERS_DETAILS: return setTransportDetails(state, action);
        case actionTypes.SET_TRANSPORT_VEHICLE: return setVehicleDetails(state, action);
        case actionTypes.SET_TRANSPORT_BANK_DETAILS: return setTransportBankDetails(state, action);
        default: return state;
    }
};

export default transportReducer;