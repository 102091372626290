const url_prifix = "api/transport-managment/transports";
export const GET_INFORMATION_BY_PINCODE = `api/pincode/`;
export const GET_TRANSPORTS = `${url_prifix}`;

export const GET_TRANSPORT_BY_ID = `${url_prifix}/%s`;
export const ADD_TRANSPORT = `api/transport-managment/otherdetails`;

export const UPDATE_TRANSPORT = `api/transport-managment/otherdetails/update`;

export const SAVE_TRANSPORT = `api/transport-managment/transport`;

export const GET_VEHICLE = `api/vehicle`;

export const GET_VEHICLE_LIST = `${url_prifix}/vehicles/list`;

export const CHANGE_STATUS = `api/transport-managment/change-status/%s`;

