import * as actionTypes from "../actions/actionTypes";
import {
    objDeepClone,
    removeWhiteSpace,
    firstLetterCapitalize,
} from "../../shared/utility";

const intialBillingOptn = [
    {
        label: "Select Billing Address",
        value: "",
        isActive: false,
    },
];

const intialShippingOptn = [
    {
        label: "Select Shipping Address",
        value: "",
        isActive: false,
    },
];

const intialFacilityOptn = [
    {
        label: "Select facility",
        value: "",
    },
];

const intialFacilityMachineOptn = [
    {
        label: "Select machine",
        value: "",
    },
];

const intialDriverOptn = [
    {
        label: "Select driver",
        value: "",
        isActive: true,
    },
];

const intialUtilityOptn = [
    {
        label: "Select Utility",
        value: "",
    },
];

const intialEquipmentOptn = [
    {
        label: "Select Equipment",
        value: "",
    },
];

const initialState = {
    billingAddressOptns: objDeepClone(intialBillingOptn),
    shippingAddressOptns: objDeepClone(intialShippingOptn),
    facilityOptions: objDeepClone(intialFacilityOptn),
    driverOptions: objDeepClone(intialDriverOptn),
    facilityMachines: [],
    facilityStaffs: [],
    fm_options: objDeepClone(intialFacilityMachineOptn),
    fs_options: [],
    isLoading: false,
    facilityList: [],
    facilityViewData: [],
    facilityUtilityOptns: objDeepClone(intialUtilityOptn),
    facilityEquipmentOptns: objDeepClone(intialEquipmentOptn),
};

const setFacilityDetails = (state, action) => {
    let billingAddressOptns = objDeepClone(intialBillingOptn);
    let shippingAddressOptns = objDeepClone(intialShippingOptn);
    let facilityOptions = objDeepClone(intialFacilityOptn);
    let driverOptions = objDeepClone(intialDriverOptn);
    let billingAddressMap = new Map();
    let shippingAddressMap = new Map();
    let dataArray = action.data;
    let fm_options = objDeepClone(intialFacilityMachineOptn);

    let tempFacilityList = [];
    let roundOff = {};
    dataArray.forEach((element) => {
        element?.client?.premises?.forEach((cp) => {
            billingAddressMap.set(
                `${cp.officeName}-${cp.addressLine1}`,
                `${cp.officeName}-${cp.addressLine1}`
            );
        });
        if(element?.processSetting && element.processSetting.length){
            element?.processSetting.forEach((item) => {
                roundOff = {
                    isInwardRoundOff: item.inward ? item.inward : false,
                    isOutwardRoundOff: item.outward ? item.outward : false,
                };
            });
        }else{
            roundOff = {
                isInwardRoundOff: false,
                isOutwardRoundOff: false,
            };
        }
        tempFacilityList.push({
            facilityId: element.facilityId,
            isActive: element.isActive,
            facilityName: element.name,
            facilityCity: element.address.city,
            facilityState: element.address.state,
            gstNumber: element.gstNumber,
            propertyOwned: element.propertyOwned === true ? "Owned" : "Rented",
            operationsCommenceDate: element.operationsCommenceDate,
            roundOff: roundOff,
        });
        if (
            Object.keys(element.address).length > 0 &&
            element.isActive === true
        ) {
            billingAddressMap.set(
                `${element.name}-${element.address.addressLine1}`,
                `${element.name}-${element.address.addressLine1}`
            );
            shippingAddressMap.set(
                `${element.name}-${element.address.addressLine1}`,
                //`${element.name}-${element.address.addressLine1}@${element.facilityId}`
                `${element.name}-${element.address.addressLine1}
                @${element.facilityId}@${element.gstRegistered}`
            );
        }

        //if(element.isActive === true){
        facilityOptions.push({
            label: element.name,
            value: element.facilityId,
            isActive: element.isActive,
            isGstRegistered: element.gstRegistered,
            address: element.address,
        });
        //}

        element.staff?.forEach((item) => {
            //if(item.designation === "Driver"){
            driverOptions.push({
                label: item.name,
                value: item.staffId,
                isActive: item.isDelete,
            });
            //}
        });
        element.facilityMachines.forEach((machine) => {
            fm_options.push({
                label: firstLetterCapitalize(machine.name),
                value: machine.facilityMachinerId,
                facilityId: element.facilityId,
            });
        });
    });

    billingAddressMap.forEach((value, key) => {
        billingAddressOptns.push({
            label: key,
            value: value,
        });
    });

    shippingAddressMap.forEach((value, key) => {
        value = value.split("@");
        shippingAddressOptns.push({
            label: removeWhiteSpace(key),
            value: removeWhiteSpace(value[0]),
            facilityId: value[1],
            isGstRegistered: value[2],
        });
    });

    return {
        ...state,
        billingAddressOptns: billingAddressOptns,
        shippingAddressOptns: shippingAddressOptns,
        facilityOptions: facilityOptions,
        driverOptions: driverOptions,
        //facilityMachines : facilityMachines,
        //facilityStaffs : facilityStaffs,
        fm_options: fm_options,
        facilityList: tempFacilityList,
    };
};

const setFacilityStaffDetails = (state, action) => {
    let fm_options = [];
    let fs_options = [];
    action.data.facilityMachines.forEach((element) => {
        fm_options.push({
            label: firstLetterCapitalize(element.name),
            value: element.facilityMachinerId,
        });
    });
    action.data.facilityStaffs.forEach((element) => {
        //if(element.designation !== "Driver"){
        fs_options.push({
            label: element.name,
            value: element.staffId,
            isActive: element.isDelete,
        });
        //}
    });
    return {
        ...state,
        facilityMachines: action.data?.facilityMachines,
        facilityStaffs: action.data?.facilityStaffs,
        fm_options: fm_options,
        fs_options: fs_options,
    };
};

const setFacilityEditData = (state, action) => {
    return {
        ...state,
        facilityViewData: action.data,
    };
};

const setFacilityUtilityOptions = (state, action) => {
    let new_array = action.data.map((m) => {
        return {
            label: m.name,
            value: `${m.utilityId}`,
        };
    });
    return {
        ...state,
        facilityUtilityOptns: [...intialUtilityOptn, ...new_array],
    };
};

const setFacilityEquipmentOptions = (state, action) => {
    // console.log('action=>',action)
    let new_array = action.data.map((m) => {
        return {
            label: m.name,
            value: `${m.safetyEquipId}`,
        };
    });
    return {
        ...state,
        facilityEquipmentOptns: [...intialEquipmentOptn, ...new_array],
    };
};

const facilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FACILITY_DETAILS:
            return setFacilityDetails(state, action);
        case actionTypes.SET_FACILTIY_STAFF_DETAILS:
            return setFacilityStaffDetails(state, action);
        case actionTypes.SET_FACILTIY_EDIT_DETAILS:
            return setFacilityEditData(state, action);
        case actionTypes.SET_FACILITY_UTILITY_OPTIONS:
            return setFacilityUtilityOptions(state, action);
        case actionTypes.GET_FACILITY_EQUIPMENT_OPTIONS:
            return setFacilityEquipmentOptions(state, action);
        default:
            return state;
    }
};

export default facilityReducer;
