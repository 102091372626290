import axios from "axios";
import * as actionTypes from "./actionTypes";
import axiosApi from "../../api";
import { showLoader, hideLoader } from "./applicationAction";
import {
    ALERT_ERROR,
    ALERT_SUCCESS,
    ALERT_WARNING,
} from "../../shared/constants/constants";
import * as customerApi from "../../shared/Api/customerManagement";
import * as materialsServices from "../../shared/Api/materialManagement";
import * as outwardApi from "../../shared/Api/outwardManagement";
import * as expenseApi from "../../shared/Api/expenseManagement";
import {
    ToastAlert,
    getServerError,
    putVariableInString,
    //dateCustomFormat,
    //removeLastComma,
    //randomStringGenrator,
} from "../../shared/utility";

export const setOutwardList = (data, totalInvoicedQty) => {
    return {
        type: actionTypes.SET_OUTWARD_LIST,
        data: data,
        totalInvoicedQty: totalInvoicedQty,
    };
};

export const setOutwardExpansesTypes = (data) => {
    return {
        type: actionTypes.SET_EXPENSESTYPES,
        data: data,
    };
};

export const setOutwardExpansesTypesArray = (data) => {
    return {
        type: actionTypes.SET_EXPENSESTYPESARRAY,
        data: data,
    };
};

export const setOutwardRatingData = (data) => {
    return {
        type: actionTypes.SET_RATINGDATA,
        data: data,
    };
};

export const setOutwardViewdata = (data) => {
    return {
        type: actionTypes.SET_OUTWARD_VIEWDATA,
        data: data,
    };
};

export const setProcessNumbers = (data) => {
    return {
        type: actionTypes.SET_PROCESS_NUBMERS,
        data: data,
    };
};

export const setOutwardIndirectTransaction = (data) => {
    return {
        type: actionTypes.SET_OUTWARD_INDIRECT_TRANSACTION,
        data: data,
    };
};

export const setOutwardUpdateRatingData = (data) => {
    return {
        type: actionTypes.SET_OUTWARD_UPDATE_RATING,
        data: data,
    };
};

export const setOutwardCustomerList = (data) => {
    return {
        type: actionTypes.SET_PROCESS_CUSTOMER,
        data: data,
    };
};

export const changeOutwardRedirect = () => {
    return {
        type: actionTypes.CHANGE_OUTWARD_REDIRECT,
    };
};

export const setOutwardMaterials = (data) => {
    return {
        type: actionTypes.SET_OUTWARD_MATERIAL,
        data: data,
    };
};

export const showOwModalsPopup = (modalName, data) => {
    switch (modalName) {
        case "sendInvoice":
            return {
                type: actionTypes.OUTWARD_INVOICE_POPUP_SHOW,
            };
        case "expenses":
            return {
                type: actionTypes.OUTWARD_EXPENSES_POPUP_SHOW,
                data: data,
            };
        case "rating":
            return {
                type: actionTypes.OUTWARD_RATING_POPUP_SHOW,
            };
        default:
            break;
    }
};

//===========================Call Api=======================

const getCustomerList = () => {
    let url = customerApi.GET_PROCESS_CUSTOMERS;
    return axiosApi.getDataRequest(url);
};

const getMaterails = () => {
    let url = materialsServices.GET_ALL_MATERIALS_LIST;
    return axiosApi.getDataRequest(url);
};

// Call Multiple Api using axios
export const getInitialDataOutwardCreate = () => {
    const requestOne = getCustomerList();
    const requestTwo = getMaterails();
    return (dispatch) => {
        // dispatch(showLoader());
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    // use/access the results
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    dispatch(setOutwardCustomerList(responseOne.data.data));
                    dispatch(setOutwardMaterials(responseTwo.data.data));
                    ///dispatch(setMaterialOptns());
                    // dispatch(hideLoader());
                })
            )
            .catch((errors) => {
                console.log("error", errors);
                let message = getServerError(errors);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                // dispatch(hideLoader());
                // react on errors.
            });
    };
};

//save inward

export const saveOutward = (OutwardId, dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = outwardApi.SAVE_OUTWARD;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                //console.log("result outward id",result)
                if (response.status === 200) {
                    //let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    if (dataObj.isDraft === false) {
                        dispatch(hideLoader());
                        //dispatch(showOwModalsPopup('sendInvoice'));
                        // dispatch(showOwModalsPopup('expenses',{invoiceNumber:invoiceNumber,OutwardId:result.data[0].OutwardId}));
                        dispatch(
                            showOwModalsPopup("expenses", {
                                outwardId: result.data[0].outwardId,
                            })
                        );
                        // dispatch(showOwModalsPopup('rating'));
                        // history.push("/outward")
                    } else {
                        history.push("/outward");
                    }
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Outward list
// export const initOutwards = (postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = outwardApi.GET_OUTWARDS;
//         axiosApi.postDataRequest(url, postData)
//             .then(response => {
//                 let result = response.data.data;
//                 let arrayObj =  [];
//                     let totalInvoicedQty = 0;
//                 if (response.status === 200) {
//                     result.forEach((element, index) => {
//                         let obj = {
//                             "outwardId": element.outwardId,
//                             "facilityId" : element.facility.facilityId,
//                             "facilityName" : element.facility.name,
//                             "invoiceNumber": element.invoiceNumber,
//                             "grandTotal": element.grandTotal,
//                             "datetime": dateCustomFormat(element.saleDate, 'DD MMMM YYYY hh:mm a', true),
//                             "status": element.isDraft === true ? "Draft" : "Submitted",
//                             "isActive": element.isActive,
//                             "isDraft": element.isDraft,
//                             "customerName": element.customer?.customerName,
//                             "isParentRow" : true
//                         }
//                         if(element.outwardMaterial.length > 1){
//                             let invoiceQty = 0;
//                             let materialName = '';
//                             let subMaterialName = '';
//                             let packagingType = '';

//                             obj["subRows"] = [];
//                             for (let i = 0; i < element.outwardMaterial.length; i++) {
//                                 totalInvoicedQty += (element.outwardMaterial[i]['invoiceQty'] ?
//                                     +element.outwardMaterial[i]['invoiceQty']
//                                     :0
//                                 );
//                                 invoiceQty += element.outwardMaterial[i]['invoiceQty']?
//                                     +element.outwardMaterial[i]['invoiceQty']
//                                     : 0;
//                                 packagingType += element.outwardMaterial[i]['packagingType']+', ';
//                                 if(!materialName.includes(element.outwardMaterial[i]['material'].materialName)){
//                                     materialName += element.outwardMaterial[i]['material'].materialName +', ';
//                                 }
//                                 if(!subMaterialName.includes(element.outwardMaterial[i]['subMaterial'].subMaterialName)){
//                                     subMaterialName += element.outwardMaterial[i]['subMaterial'].subMaterialName +', ';
//                                 }

//                                 obj["subRows"].push({
//                                     invoiceQty : element.outwardMaterial[i]['invoiceQty'],
//                                     materialName : element.outwardMaterial[i]['material'].materialName,
//                                     subMaterialName : element.outwardMaterial[i]['subMaterial'].subMaterialName
//                                 })
//                             }
//                             obj["invoiceQty"] = invoiceQty;
//                             obj["materialName"] = removeLastComma(materialName);
//                             obj["subMaterialName"] = removeLastComma(subMaterialName);
//                             obj["packagingType"] = removeLastComma(packagingType);
//                             arrayObj.push(obj);
//                         }else{
//                             totalInvoicedQty += (element.outwardMaterial?.[0] ?
//                                 +element.outwardMaterial?.[0]?.['invoiceQty']
//                                 :0
//                             );
//                             obj["invoiceQty"] = element.outwardMaterial?.[0] ?
//                                 element.outwardMaterial?.[0]?.['invoiceQty']:0;
//                             obj["packagingType"] = element.outwardMaterial?.[0]?.['packagingType'];
//                             obj["materialName"] = element.outwardMaterial?.[0]?.['material'].materialName;
//                             obj["subMaterialName"] = element.outwardMaterial?.[0]?.['subMaterial'].subMaterialName;
//                             arrayObj.push(obj);
//                         }
//                     });
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                 }
//                 totalInvoicedQty = +(totalInvoicedQty.toFixed(2));

//                 dispatch(setOutwardList(arrayObj,totalInvoicedQty));
//                 dispatch(hideLoader());
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

// getAvailableQty
export const getProcessNumbers = (pt, m, subM, processNum, facilityId) => {
    return (dispatch) => {
        let select_msg =
            pt === "sorting"
                ? "sort number"
                : pt === "baling"
                ? "batch number"
                : pt === "lumping"
                ? "lump number"
                : "process number";
        let obj = [
            { label: `Select ${select_msg}`, value: "", availabelQty: 0 },
        ];
        if (pt && m && subM) {
            dispatch(showLoader());
            let url = outwardApi.GET_AVAILABLE_QTY;
            let postObj = {
                packagingType: pt,
                materialId: m,
                subMaterialId: subM,
                processNumber: processNum,
                facilityId: facilityId,
            };
            return axiosApi
                .postDataRequest(url, postObj)
                .then(
                    (response) => {
                        let result = response.data.data;
                        if (response.status === 200) {
                            if (result.length > 0) {
                                result.forEach((item) => {
                                    obj.push({
                                        label: item.processNumber,
                                        value: item.processNumber,
                                        availabelQty: item.availableQty,
                                    });
                                });
                            }
                            dispatch(setProcessNumbers(obj));
                            dispatch(hideLoader());
                        } else {
                            ToastAlert({
                                type: ALERT_WARNING,
                                msg: response.data.message,
                            });
                            dispatch(hideLoader());
                        }
                    },
                    (error) => {
                        dispatch(setProcessNumbers(obj));
                        dispatch(hideLoader());
                    }
                )
                .catch((error) => {
                    console.log("error", error);
                    let message = getServerError(error);
                    ToastAlert({ type: ALERT_ERROR, msg: message });
                    dispatch(hideLoader());
                });
        } else {
            dispatch(setProcessNumbers(obj));
        }
    };
};
// Outward By id
export const getOutwardById = (id) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(outwardApi.GET_OUTWARD_BY_ID, id);
        return axiosApi
            .getDataRequest(url)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200 && response.data.success === true) {
                    result["outwardMatlList"] = [];
                    for (let i = 0; i < result.outwardMaterial.length; i++) {
                        let arrayItem = result.outwardMaterial[i];
                        let matlName = "";
                        let subMatlName = "";
                        if (
                            arrayItem["material"].materialNamePerFacility
                                .length > 0
                        ) {
                            let matlNameByFacility = arrayItem[
                                "material"
                            ].materialNamePerFacility.filter(
                                (mf) =>
                                    mf.facilityId === result.facility.facilityId
                            )?.[0];
                            if (matlNameByFacility) {
                                matlName = matlNameByFacility.materialAliasName;
                            } else {
                                matlName = arrayItem.material.materialName;
                            }
                        } else {
                            matlName = arrayItem.material.materialName;
                        }

                        if (
                            arrayItem["subMaterial"].subMaterialNamePerFacility
                                .length > 0
                        ) {
                            let subMatlNameByFacility = arrayItem[
                                "subMaterial"
                            ].subMaterialNamePerFacility.filter(
                                (smf) =>
                                    smf.facilityId ===
                                    result.facility.facilityId
                            )?.[0];
                            if (subMatlNameByFacility) {
                                subMatlName =
                                    subMatlNameByFacility.subMaterialAliasName;
                            } else {
                                subMatlName =
                                    arrayItem.subMaterial.subMaterialName;
                            }
                        } else {
                            subMatlName = arrayItem.subMaterial.subMaterialName;
                        }
                        let obj = {
                            outwardMaterialId: arrayItem.outwardMaterialId,
                            materialId: arrayItem.material.materialId,
                            materialName: matlName,
                            materialActive: arrayItem.material.isActive,
                            subMaterialId: arrayItem.subMaterial.subMaterialId,
                            subMaterialName: subMatlName,
                            // subMaterialActive:
                            //     arrayItem.subMaterial.gstPercentage,
                            // subMatlGst: arrayItem.subMaterial.gstPercentage,
                            subMaterialActive:
                                arrayItem.subMaterial.outwardGSTPercentage,
                            subMatlGst: arrayItem.subMaterial.outwardGSTPercentage,
                            gstAmount: +arrayItem.gstAmount,
                            gstPercentage: +arrayItem.gstPercentage,
                            bagCount: +arrayItem.bagCount,
                            price: +arrayItem.price,
                            reverseChargeDiff: +arrayItem.reverseChargeDiff,
                            amount: +arrayItem.amount,
                            invoiceQty: +arrayItem.invoiceQty,
                            actualInvoiceQty: +arrayItem.actualInvoiceQty,
                            outwardMaterialProcess:
                                arrayItem.outwardMaterialProcess,
                            packagingType: arrayItem.packagingType,
                            processNumber: arrayItem.processNumber,
                            discountQty: arrayItem.discountQty,
                            discountQtyPercentage:
                                arrayItem.discountQtyPercentage,
                            quantity: arrayItem.quantity,
                        };
                        result["outwardMatlList"].push(obj);
                    }
                    dispatch(setOutwardViewdata(result));
                    dispatch(
                        setOutwardIndirectTransaction(
                            result.outwardIndirectTransaction
                        )
                    );
                    dispatch(setOutwardUpdateRatingData(result.outwardRating));
                    dispatch(hideLoader());
                } else {
                    dispatch(setOutwardViewdata(result));
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Indirect Expenses Type
export const getOwExpensesTypes = () => {
    return (dispatch) => {
        // dispatch(showLoader());
        axiosApi
            .getDataRequest(expenseApi.EXPENSES_MASTER)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200) {
                    let arrayObj = [];
                    result.forEach((element, index) => {
                        if (element.isIndirectExpenses === true) {
                            let obj = {
                                expensesHeadId: element.expensesHeadId,
                                expensesName: element.expensesName,
                            };
                            arrayObj.push(obj);
                        }
                    });
                    dispatch(setOutwardExpansesTypes(arrayObj));
                    // dispatch(hideLoader());
                } else {
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    // dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Expenses data save
export const saveOwExpensesTypes = (outwardId, dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = expenseApi.EXPENSES;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    // let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    //dispatch(showOwModalsPopup('sendInvoice'));
                    dispatch(
                        showOwModalsPopup("expenses", { outwardId: outwardId })
                    );
                    //history.push("/inward")
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Rating data save
export const saveOwRatingData = (dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = outwardApi.OUTWARD_ADD_RATING;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    // let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    dispatch(showOwModalsPopup("rating", history));
                    // dispatch(showOwModalsPopup('sendInvoice'));
                    //history.push("/inward")
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// //Change outward status
// export const updateOutwardStatus = (status, id, postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = putVariableInString(
//             outwardApi.CHANGE_OUTWARD_STATUS,id
//         );
//         axiosApi.putDataRequest(url, {isActive:status})
//             .then( response => {
//                 let result = response.data;
//                 if(response.status === 200){
//                     ToastAlert({type:ALERT_SUCCESS, msg:result.message});
//                     dispatch(initOutwards(postData));
//                 }else{
//                     ToastAlert({type:ALERT_ERROR, msg:response.data.message});
//                 }
//                 dispatch(hideLoader());
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }
