import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    customers: [],
    banklistArray: [],
    isLoading : false
};

const addbank = ( state, action ) => {
    let bankData = [...state.banklistArray, action.data];
    localStorage.setItem("bank", bankData);
    return {
        ...state,
        banks: bankData,
    };
};

// const addCustomer = ( state, action ) => {
//     let materialData = [...state.materials, action.data];
//     return {
//         ...state,
//         materials: materialData,
//     };
// };

// const UpdateCustomer = ( state, action ) => {
//     let array = [...state.materials];
//     let updatedData = action.data;
//     let updateId = action.id;
//     let index = array.findIndex(obj => obj.materialId === updateId);
//     array[index] = updatedData;
//     let materialData = array;
//     return {
//         ...state,
//         materials: materialData,
//     };
// };

const removeCustomer = (state, action) => {
    return state;
};

const setCustomer = (state, action) => {
    return {
        ...state,
        customers: action.customers,
        isLoading: true
    };
};

const fetchCustomersFailed = (state, action) => {
    return updateObject( state, { error: true } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        //case actionTypes.ADD_CUSTOMER: return addCustomer( state, action );
        //case actionTypes.UPDATE_CUSTOMER: return UpdateCustomer( state, action );
        case actionTypes.ADD_BANK: return addbank(state, action);
        case actionTypes.REMOVE_CUSTOMER: return removeCustomer(state, action);
        case actionTypes.SET_CUSTOMERS: return setCustomer(state, action);    
        case actionTypes.FETCH_CUSTOMERS_FAILED: return fetchCustomersFailed(state, action);
        default: return state;
    }
};

export default reducer;