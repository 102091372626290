import axios from "axios";
import * as actionTypes from "./actionTypes";
import axiosApi from "../../api";
import { showLoader, hideLoader } from "./applicationAction";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../shared/constants/constants";
import * as vendorApi from "../../shared/Api/vendorManagement";
import * as materialsServices from "../../shared/Api/materialManagement";
import {
    ToastAlert,
    getServerError,
    putVariableInString,
    //randomStringGenrator,
} from "../../shared/utility";

export const clearData = () => {
    return {
        type: actionTypes.CLEAR_DATA,
    };
};

export const setVendorDetails = (data) => {
    return {
        type: actionTypes.SET_VENDOR_DETAILS,
        details: data,
    };
};

export const setVendors = (data) => {
    return {
        type: actionTypes.SET_VENDORS,
        vendors: data,
    };
};

export const setMaterials = (data) => {
    return {
        type: actionTypes.SET_MATERIALS,
        materials: data,
    };
};

export const setMaterialOptns = () => {
    return {
        type: actionTypes.SET_MATERIALS_OPTIONS,
    };
};

export const deleteVendorMaterial = (vendorMaterialId, vendorId) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.DELETE_VENDOR_MATERIAL,
            vendorId
        );
        url = `${url}${vendorMaterialId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deleteVendorBank = (vendorBankDetailId, vendorId) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(vendorApi.DELETE_VENDOR_BANK, vendorId);
        url = `${url}${vendorBankDetailId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: result.message });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deleteVendorPremise = (premiseId, vendorId) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.DELETE_VENDOR_PREMISE,
            vendorId
        );
        url = `${url}${premiseId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: result.message });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deleteVendorPremiseCP = (obj, vendorId, premiseId) => {
    const { premiseContactPersonId } = obj;
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.DELETE_VENDOR_PREMISE,
            vendorId
        );
        url = `${url}${premiseId}/contact-person/${premiseContactPersonId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deletesocialSecurity = (obj, vendorId) => {
    const { vendorSocialSecuritySchemeId, otherID } = obj;
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.DELETE_VENDOR_SOCIALSECURITY,
            vendorId
        );
        url = `${url}/${otherID}/social-security/${vendorSocialSecuritySchemeId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deleteBankDoc = (obj) => {
    const { vendorBankDetailId, docId, vendorId } = obj;
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(vendorApi.DELETE_VENDOR_BANK, vendorId);
        url = `${url}${vendorBankDetailId}/document/${docId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deleteOtherDoc = (obj) => {
    const { vendorOtherDetailsId, docId, vendorId } = obj;
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(vendorApi.GET_VENDOR_BY_ID, vendorId);
        url = `${url}/other/${vendorOtherDetailsId}/document/${docId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const deletePremiseDoc = (obj) => {
    const { premiseId, docId, vendorId } = obj;
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.DELETE_VENDOR_PREMISE,
            vendorId
        );
        url = `${url}${premiseId}/document/${docId}`;
        return axiosApi
            .deleteDataRequest(url)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({
                        type: ALERT_SUCCESS,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                    return result;
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// vendor premise

export const saveVendor = (dataObj, history, allVendors, vendorTypes) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = vendorApi.ADD_VENDOR;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    history.push({
                        pathname:
                            "vendor/vendor-details-edit/" +
                            result.data.vendorId,
                        state: {
                            list: allVendors,
                            vendorTypes: vendorTypes,
                        },
                    });
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

export const saveVendorAllData = (vendorId, dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(
            vendorApi.ADD_UPDATE_VENDOR_DETAILS,
            vendorId
        );
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(clearData());
                    dispatch(hideLoader());
                    history.push("/vendor");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

const getVendorById = (id) => {
    let url = putVariableInString(vendorApi.GET_VENDOR_BY_ID, id);
    return axiosApi.getDataRequest(url);
};

const getMaterails = () => {
    let url = materialsServices.GET_ALL_MATERIALS_LIST;
    return axiosApi.getDataRequest(url);
};

// Call Multiple Api using axios
export const callMultipleReq = (id) => {
    const requestOne = getVendorById(id);
    const requestTwo = getMaterails();
    let responseOne = {};
    let responseTwo = [];
    return (dispatch) => {
        dispatch(showLoader());
        return axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    // use/access the results
                    responseOne = responses[0];
                    responseTwo = responses[1];
                    dispatch(setVendorDetails(responseOne.data.data));
                    dispatch(setMaterials(responseTwo.data.data));
                    dispatch(setMaterialOptns());
                    dispatch(hideLoader());
                })
            )
            .catch((errors) => {
                console.log("error", errors);
                dispatch(setVendorDetails(responseOne));
                dispatch(setMaterials(responseTwo));
                dispatch(setMaterialOptns());
                let message = getServerError(errors);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
                // react on errors.
            });
    };
};

// export const initVendors = () => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = vendorApi.GET_VENDORS;
//         axiosApi.getDataRequest(url)
//             .then(response => {
//                 let result = response.data.data;
//                 if (response.status === 200) {
//                     let arrayObj =  result;
//                     for(let i = 0; i < arrayObj.length; i++){
//                         if(arrayObj[i]['vendorType'] !== 'Waste Collector' &&
//                             arrayObj[i]['vendorType'] !== 'Citizens')
//                         {
//                             let index = arrayObj[i]['vendorPremiseData'].findIndex(obj => {
//                                 return obj['officeType'] === 'Head Office';
//                             });
//                             if(index !== (-1)){
//                                 arrayObj[i]['city'] = null;
//                                 arrayObj[i]['state'] = null;
//                                 //arrayObj[i]['rating'] = 0;
//                                 arrayObj[i]['contactNumber'] = null;
//                                 arrayObj[i]['premiseEmail'] = null;

//                                 if(arrayObj[i]['vendorPremiseData'].length > 0){
//                                     arrayObj[i]['city'] = arrayObj[i]['vendorPremiseData'][index]['city']
//                                     arrayObj[i]['state'] = arrayObj[i]['vendorPremiseData'][index]['state']
//                                     //arrayObj[i]['rating'] = 0;
//                                     arrayObj[i]['contactNumber'] = arrayObj[i]['vendorPremiseData'][index]['contactNumber']
//                                         ? arrayObj[i]['vendorPremiseData'][index]['contactNumber']
//                                         : null ;
//                                     arrayObj[i]['premiseEmail'] = arrayObj[i]['vendorPremiseData'][index]['premiseEmail']
//                                         ? arrayObj[i]['vendorPremiseData'][index]['premiseEmail']
//                                         :null;
//                                 }
//                                 arrayObj[i]['vendorPremiseData'].splice(index, 1);
//                             }
//                             arrayObj[i].isParentRow = true;
//                             arrayObj[i].subRows = arrayObj[i]['vendorPremiseData'];
//                             delete arrayObj[i].vendorPremiseData;
//                         }else{
//                             arrayObj[i]['city'] = arrayObj[i]['vendorOtherData'].length > 0
//                                 ? arrayObj[i]['vendorOtherData'][0]['city'] :null;
//                             arrayObj[i]['state'] = arrayObj[i]['vendorOtherData'].length > 0
//                                 ? arrayObj[i]['vendorOtherData'][0]['state'] : null;
//                             //arrayObj[i]['rating'] = 0;
//                             arrayObj[i]['contactNumber'] = arrayObj[i]['mobileNumber'];
//                             arrayObj[i]['premiseEmail'] = arrayObj[i]['email'];
//                         }
//                         arrayObj[i].isParentRow = true;
//                         delete arrayObj[i].vendorPremiseData;
//                     }
//                     dispatch(setVendors(arrayObj));
//                     dispatch(hideLoader());
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                     dispatch(hideLoader());
//                 }
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

// export const updateVendorStatus = (status, id) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = putVariableInString(
//             vendorApi.CHANGE_VENDOR_STATUS,id
//         );
//         axiosApi.putDataRequest(url, {isActive:status})
//             .then( response => {
//                 let result = response.data;
//                 if(response.status === 200){
//                     ToastAlert({type:ALERT_SUCCESS, msg:result.message});
//                     dispatch(initVendors());
//                 }else{
//                     ToastAlert({type:ALERT_ERROR, msg:response.data.message});
//                 }
//                 dispatch(hideLoader());
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }
