import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
// import { ToastContainer, Slide //Zoom, Flip, Bounce
// } from 'react-toastify';

import TopNav from "../../components/Navigation/Toolbar/Topnav";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
// import Spinner from '../../components/UI/Spinner/Spinner';

class Layout extends Component {
    state = {
        showSideDrawer: false,
    };

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    };

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    };

    htmlContentPage = () => {
        let pathString = this.props.location.pathname;
        if (pathString.includes("pdf") === true) {
            return (
                <main>
                    <div className="content-page" style={{ marginLeft: "0" }}>
                        <div className="content">
                            <Container fluid>{this.props.children}</Container>
                        </div>
                    </div>
                </main>
            );
        } else {
            return (
                <>
                    <SideDrawer />
                    <main>
                        <div className="content-page">
                            <div className="content">
                                <TopNav {...this.props} />
                                <Container fluid>
                                    {this.props.children}
                                </Container>
                            </div>
                        </div>
                    </main>
                </>
            );
        }
    };

    render() {
        return <div id="app-container">{this.htmlContentPage()}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated,
    };
};

export default connect(mapStateToProps, {})(Layout);
