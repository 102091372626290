import Keycloak from '../keycloak';

const initKeycloak = () => {
    return Keycloak.init({onLoad: 'login-required', checkLoginIframe: false})
};
  
const doLogin = Keycloak.login;
  
const doLogout = Keycloak.logout;
  
const getToken = () => Keycloak.token;
  
const isLoggedIn = () => !!Keycloak.token;
  
const updateToken = (successCallback) =>
    Keycloak.updateToken(5)
        .then(successCallback)
        .catch(doLogin);
  
const getUsername = () => Keycloak.tokenParsed?.preferred_username;
const getUserinfo = () => Keycloak.tokenParsed;
const getAllinfo = () => Keycloak;
  
const hasRole = (roles) => {
    if(Keycloak && roles){
        return roles.some(r => {
            //console.log('rol', r)
            //console.log("realm....", realm)
            //console.log("resource....", resource)
            if(getUserinfo().realm_access.roles.includes('Super Admin')){
                const realm =  Keycloak.hasRealmRole(r);
                return realm;
            }else{
                const resource = Keycloak.hasResourceRole(r, 'fms');
                return resource;
            }
        });
    }
    return false;
}
  
const keycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    getUserinfo,
    getAllinfo,
    hasRole,
};
  
export default keycloakService;