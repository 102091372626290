import React, {Fragment } from 'react';
import { useTable} from 'react-table';
//import TableContainer from '@material-ui/core/TableContainer';
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from 'react-i18next';

// =====  Basic Table ===============

export const SimpleReactTable = ({ columns, data, renderRowSubComponent }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //footerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
    );
    const { t, i18n } = useTranslation();
    return (
        // <Table bordered hover {...getTableProps()}>
        <div className="d-none d-md-block">
            <MaUTable {...getTableProps()} className="mt-3 table-style">
                <TableHead>
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return column.hideHeader === false ? null : (
                                    <TableCell {...column.getHeaderProps()} 
                                        style={{minWidth: column.minWidth, width: column.width }}
                                    >
                                        {/* <div {...column.getSortByToggleProps()}> */}
                                        {t(column.render('Header'))}
                                        {/* {generateSortingIndicator(column)} */}
                                        {/* </div> */}
                                        {/* <Filter column={column} /> */}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHead>
                {rows.length > 0 ? 
        
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <TableRow>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        );
                                    })}
                                </TableRow>
                                {row.isExpanded && (
                                    <TableRow>
                                        <TableCell colSpan={visibleColumns.length}>
                                            {renderRowSubComponent(row)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Fragment>
                        );
                    })}
                </TableBody>
                : <TableBody> 
                    <TableRow className="text-center mt-2" >  
                        <TableCell colSpan="20" className="text-center mt-2">{t("No Record Available")}</TableCell> 
                    </TableRow>  
                </TableBody> 
            }
            </MaUTable>
        </div>
    );
};