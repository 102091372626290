const url_prifix = "api/vendor-management/vendors";
export const GET_INFORMATION_BY_PINCODE = `api/pincode/`;
export const GET_VENDORS = `${url_prifix}/list`;
export const GET_VENDORTYPES = `${url_prifix}/vendor-type/list/`;
export const GET_PROCESS_VENDORS = `${url_prifix}/other/list/`;
export const GET_VENDOR_BY_ID = `${url_prifix}/%s`;
export const ADD_VENDOR = `${url_prifix}`;
export const ADD_UPDATE_VENDOR_DETAILS = `${url_prifix}/%s/all-detail`;
export const DELETE_VENDOR = `${url_prifix}/%s`;
export const CHANGE_VENDOR_STATUS = `${url_prifix}/change-status/%s`;
export const DELETE_VENDOR_BANK = `${url_prifix}/%s/bank/`;
export const DELETE_VENDOR_SOCIALSECURITY = `${url_prifix}/%s/other`;
export const DELETE_VENDOR_PREMISE = `${url_prifix}/%s/premise/`;
export const DELETE_VENDOR_MATERIAL = `${url_prifix}/%s/material/`;
