import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import keycloakService from '../../../services/keycloakService';
import * as actions from '../../../store/actions/index';

class Logout extends Component {
    componentDidMount () {
        //localStorage.removeItem('token');
        keycloakService.doLogout();
        localStorage.clear();
        //localStorage.removeItem('token');
        //this.props.keycloak.logout();
        //this.props.onLogout();
    }

    render () {
        return <Redirect to="/"/>;
    }
}

const mapStateToProps = state => {
	return {
		keycloak: state.auth.keycloak
	};
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);