import dashboardIcon from "../assets/sidedrawicn/dashboard.svg";
import attendanceIcon from "../assets/sidedrawicn/attendance.svg";
import expenseIcon from "../assets/sidedrawicn/expense.svg";
import helpIcon from "../assets/sidedrawicn/help.svg";
import inventoryIcon from "../assets/sidedrawicn/inventory.svg";
import inwardIcon from "../assets/sidedrawicn/inward.svg";
import outwardIcon from "../assets/sidedrawicn/outward.svg";
import processesIcon from "../assets/sidedrawicn/processor.svg";
import registerIcon from "../assets/sidedrawicn/registration.svg";
import reportIcon from "../assets/sidedrawicn/report.svg";
import alertIcon from "../assets/sidedrawicn/alert.svg";
import setting from "../assets/sidedrawicn/setting.svg";
// import Ico2 from "../assets/img/manager.svg";
// import Ico3 from "../assets/img/delivery.svg";
// import Ico4 from "../assets/img/admin.svg";
// import logOut from "../assets/img/logout.svg";

import asyncComponent from "../hoc/asyncComponent/asyncComponent";
import Logout from "../containers/Auth/Logout/Logout";

//import TransporterTable from "../containers/Transporter/TransporterTable";
// import InnerSorting from "./containers/Sorting/InnerSorting";

import MaterialWise from "../containers/Sorting/materialwise/MaterialWise";

// import Sorting from "./containers/Sorting/sorting";

//import TransporterTable from "./components/Transporter/TransporterTable";

//====Dashboard===============
const dashboard = asyncComponent(() => {
    return import("../containers/Dashboard/");
});

//====Material===============
const materials = asyncComponent(() => {
    return import("../containers/Materials/Material");
});

//====Customer===================
const customers = asyncComponent(() => {
    return import("../containers/Customers/Customer");
});

const customerDetails = asyncComponent(() => {
    return import("../containers/Customers/CustomerEditDetails");
});

const customerViewDetails = asyncComponent(() => {
    return import("../containers/Customers/CustomerViewDetails");
});

const customerViewDetailsPdf = asyncComponent(() => {
    return import("../containers/Customers/CustomerViewDetailsPdf");
});

//====Expense Managment===================
const ExpenseManagmentHead = asyncComponent(() => {
    return import(
        "../containers/ExpenseManagment/admin/ExpensesManagementHead"
    );
});
const ExpenseManagment = asyncComponent(() => {
    return import("../containers/ExpenseManagment/ExpenseManagment");
});
const expenseList = asyncComponent(() => {
    return import("../containers/ExpenseManagment/ExpenseList");
});

//====Facility===================
const facility = asyncComponent(() => {
    return import("../containers/Facility/Facility");
});
const facilityDetails = asyncComponent(() => {
    return import("../containers/Facility/FacilityDetail");
});
const facilityList = asyncComponent(() => {
    return import("../containers/Facility/Facility-List");
});
//====Process===================
const processManagement = asyncComponent(() => {
    return import("../containers/ProcessSetting/Process");
});

//====Organization===================
const organization = asyncComponent(() => {
    return import("../containers/Organization/Organization");
});
// const organizationDepartment = asyncComponent(() => {
// 	return import("./containers/Organization/OrganizationDepartment");
// });

//====USer Management ===================
const user = asyncComponent(() => {
    return import("../containers/UserManagement/User");
});

//====Staff===================
const staff = asyncComponent(() => {
    return import("../containers/Staff/Staff");
});
const staffDetails = asyncComponent(() => {
    return import("../containers/Staff/StaffDetail");
});

//====Transporter===================
const transporter = asyncComponent(() => {
    return import("../containers/Transporter/TransporterForm");
});
const transporterDetail = asyncComponent(() => {
    return import("../containers/Transporter/TransporterDetail");
});
const transporterDetailPdf = asyncComponent(() => {
    return import("../containers/Transporter/TransporterDetailPdf");
});

// const transporterTable= asyncComponent(() => {
//   return import( './containers/Transporter/TransporterTable')
// });

//====Vendor==============================
const vendors = asyncComponent(() => {
    return import("../containers/Vendor/Vendor");
});

const vendorEditDetail = asyncComponent(() => {
    //return import('./components/Vendor/Vendor-detail');
    return import("../containers/Vendor/VendorEditDetails");
});

const vendorViewDetail = asyncComponent(() => {
    return import("../containers/Vendor/VendorViewDetails");
});

const vendorViewDetailPdf = asyncComponent(() => {
    return import("../containers/Vendor/VendorViewDetailsPdf");
});

//============== Sorting & Process =====================
const sorting = asyncComponent(() => {
    return import("../containers/Sorting/sorting");
});

const process = asyncComponent(() => {
    return import("../containers/Sorting/Process");
});

const sortingProcessList = asyncComponent(() => {
    return import("../containers/Sorting/SortingProcessList");
});

const sortingProcess = asyncComponent(() => {
    return import("../containers/Sorting/SortingProcess");
});

const sortingProcessPdf = asyncComponent(() => {
    return import("../containers/Sorting/SortingProcessPdf");
});

// const innerSorting = asyncComponent(() => {
//   return import("./containers/Sorting/InnerSorting");
// });
const materialWiseProcess = asyncComponent(() => {
    return import("../containers/Sorting/materialwise/materialwiseProcess");
});
const materialWiseInnerSorting = asyncComponent(() => {
    return import(
        "../containers/Sorting/materialwise/materialwiseInnerSorting"
    );
});
//============== baling & Process =====================
const baling = asyncComponent(() => {
    return import("../containers/Baling/Baling");
});

const processbaling = asyncComponent(() => {
    return import("../containers/Baling/ProcessBaling");
});
const balingProcessList = asyncComponent(() => {
    return import("../containers/Baling/BalingProcessList");
});

const processBalingView = asyncComponent(() => {
    return import("../containers/Baling/ProcessBalingView");
});

const processBalingViewPdf = asyncComponent(() => {
    return import("../containers/Baling/ProcessBalingViewPdf");
});

// Lumpping

const lumping = asyncComponent(() => {
    return import("../containers/Lumping/Lumping");
});

const lumpingProcessList = asyncComponent(() => {
    return import("../containers/Lumping/LumpingProcessList");
});

const processLumping = asyncComponent(() => {
    return import("../containers/Lumping/ProcessLumping");
});

const processLumpingView = asyncComponent(() => {
    return import("../containers/Lumping/ProcessLumpingView");
});

const processLumpingViewPdf = asyncComponent(() => {
    return import("../containers/Lumping/processLumpingViewPdf");
});

//=========================Inward=============

const inward = asyncComponent(() => {
    return import("../containers/Inward/Inward");
});

const addInward = asyncComponent(() => {
    return import("../containers/Inward/InwardForm");
});

const viewInwrdDetails = asyncComponent(() => {
    return import("../containers/Inward/InwardViewDetails");
});

const viewInwrdPdf = asyncComponent(() => {
    return import("../containers/Inward/InwardPdfViewDetails");
});
//===================================

//========================Outward==============
const outward = asyncComponent(() => {
    return import("../containers/Outward/Outward");
});

const addOutward = asyncComponent(() => {
    return import("../containers/Outward/OutwardForm");
});

const viewOutwrdDetails = asyncComponent(() => {
    return import("../containers/Outward/OutwardViewDetails");
});

const viewOutwrdDetailsPdf = asyncComponent(() => {
    return import("../containers/Outward/OutwardViewDetailsPdf");
});
//==================Inventory=================
const inventory = asyncComponent(() => {
    return import("../containers/Inventory/Inventory");
});

const sortedinventory = asyncComponent(() => {
    return import("../containers/Inventory/SortedInventory");
});

//===================================
const asyncPageNotFound = asyncComponent(() => {
    return import("../components/PageNotFound");
});

const asyncUnderConstruction = asyncComponent(() => {
    return import("../components/UnderConstruction");
});

//================= Reports =================

const Reports = asyncComponent(() => {
    return import("../containers/Reports/Report");
});

const inwardReports = asyncComponent(() => {
    return import("../containers/Reports/Inward/Inward");
});

const outwardReports = asyncComponent(() => {
    return import("../containers/Reports/Outward/Outward");
});

const sortingReports = asyncComponent(() => {
    return import("../containers/Reports/Sorting/SortingReport");
});

const balingReports = asyncComponent(() => {
    return import("../containers/Reports/Baling/BalingReport");
});

const lumpingReports = asyncComponent(() => {
    return import("../containers/Reports/Lumping/LumpingReport");
});

//==================================

//====Client Routes==============================
const client = asyncComponent(() => {
    return import("../containers/Client/Client");
});

const clientViewDetails = asyncComponent(() => {
    return import("../containers/Client/ClientViewDetails");
});

//==============User Route===============
const UsersList = asyncComponent(() => {
    return import("../containers/User/User");
});
const userDetails = asyncComponent(() => {
    return import("../containers/User/User-details");
});

//==============Profile Route===============
const Profile = asyncComponent(() => {
    return import("../containers/Profile/Profile");
});
// const userDetails = asyncComponent(() => {
// 	return import("./containers/User/User-details");
// });

export const AuthenticatedRoutes = [
    {
        path: "#/",
        label: "Admin",
        icon: setting,
        component: asyncPageNotFound,
        sideMenu: false,
        menu_id: "1",
        permissions: ["1.1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7", "1.8"],
        roles: ["Guard", "Manager", "Admin"],
        routes: [
            {
                path: "/facility-registration",
                label: "Facility Registration",
                component: facility,
                sideMenu: false,
                submenu_id: "1.1",
                roles: ["Guard", "Manager", "Admin"],
                routes: [
                    {
                        path: "/facility-registration/add-facility",
                        label: "Add Facility",
                        component: facilityDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/facility-registration/facility-details/:id",
                        label: "Facility-details",
                        component: facilityDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/facility/facility-list",
                        label: "Facility-list",
                        component: facilityList,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            {
                path: "/users",
                label: "Users",
                component: UsersList,
                sideMenu: false,
                submenu_id: "1.2",
                roles: ["Guard", "Manager", "Admin"],
                routes: [
                    {
                        path: "/users/user-registration",
                        label: "User Registration",
                        component: userDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            {
                path: "/materials",
                label: "Material Management",
                component: materials,
                sideMenu: false,
                submenu_id: "1.3",
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/organization",
                label: "Organization",
                component: organization,
                sideMenu: false,
                submenu_id: "1.4",
                roles: ["Guard", "Manager", "Admin"],
                // routes: [
                // 	{
                // 		path: "/organization-department",
                // 		label: "Department",
                // 		component: organizationDepartment,
                // 		sideMenu: false,
                //		roles: [ 'Guard', 'Manager', 'Admin'],
                // 	},
                // ]
            },
            {
                path: "/admin-expense-setting",
                label: "Expense Setting",
                component: ExpenseManagmentHead,
                sideMenu: false,
                submenu_id: "1.5",
                roles: ["Guard", "Manager", "Admin"],
                routes: [],
            },
            {
                path: "/process-settings",
                label: "Process Setting",
                component: processManagement,
                sideMenu: false,
                submenu_id: "1.6",
                roles: ["Guard", "Manager", "Admin"],
                // routes: [
                //   {
                //     path: "/process-management",
                //     label: "Process Management",
                //     component: processManagement,
                //     sideMenu: false,
                //		roles: [ 'Guard', 'Manager', 'Admin'],
                //   },
                // ],
            },
            {
                path: "/staff-registration",
                label: "Staff Registration",
                component: staff,
                sideMenu: false,
                submenu_id: "1.7",
                roles: ["Guard", "Manager", "Admin"],
                routes: [
                    {
                        path: "/staff-registration/staff-details/:id",
                        label: "Staff-details",
                        component: staffDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            {
                path: "/requisition",
                label: "Requisition",
                component: asyncUnderConstruction,
                sideMenu: false,
                submenu_id: "1.8",
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "/",
        label: "Dashboard",
        component: dashboard,
        icon: dashboardIcon,
        sideMenu: false,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    {
        path: "/dashboard",
        label: "Dashboard",
        component: dashboard,
        icon: dashboardIcon,
        sideMenu: true,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    {
        path: "/client",
        label: "Client",
        component: client,
        icon: inwardIcon,
        sideMenu: true,
        roles: ["Super Admin"],
        permissions: [],
        routes: [
            {
                path: "/client/client-details/:id",
                label: "Client-details",
                component: clientViewDetails,
                sideMenu: false,
                roles: ["Super Admin"],
            },
        ],
    },
    {
        path: "/inward",
        label: "Inward",
        component: inward,
        icon: inwardIcon,
        sideMenu: true,
        menu_id: "8",
        permissions: ["8.1", "8.1.2"],
        roles: ["Guard", "Manager", "Admin"],
        routes: [
            {
                path: "/add-inward",
                label: "Add Inward",
                component: addInward,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/inward/inward-details/:id",
                label: "Inward-Details",
                component: viewInwrdDetails,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/inward/inward-pdf/:id",
                label: "Inward-Details",
                component: viewInwrdPdf,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "#/",
        label: "Processes",
        icon: processesIcon,
        component: asyncPageNotFound,
        sideMenu: true,
        menu_id: "10",
        permissions: ["10.1", "10.1.2", "10.2", "10.2.2", "10.3", "10.3.2"],
        roles: ["Guard", "Manager", "Admin"],
        routes: [
            {
                path: "/sorting",
                label: "Sorting",
                component: sorting,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["10.3", "10.3.2"],
                routes: [
                    // {
                    // 	path: "/sorting/process/:Id",
                    // 	label: "Process",
                    // 	component: sortingProcess,
                    // 	sideMenu: false,
                    //	roles: [ 'Guard', 'Manager', 'Admin'],
                    // },
                    // {
                    // 	path: "/process/:Id",
                    // 	label: "Process",
                    // 	component: process,
                    // 	sideMenu: false,
                    //	roles: [ 'Guard', 'Manager', 'Admin'],
                    // },
                    // {
                    // 	path: "/material-wise-process",
                    // 	label: "Process",
                    // 	component: materialWiseProcess,
                    // 	sideMenu: false,
                    //	roles: [ 'Guard', 'Manager', 'Admin'],
                    // },

                    {
                        path: "/sorting-process",
                        label: "Sorting Process",
                        component: sortingProcessList,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                        routes: [
                            {
                                path: "/process/:Id",
                                label: "Process",
                                component: process,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/material-wise-process",
                                label: "Process",
                                component: materialWiseProcess,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/sorting/process/:Id",
                                label: "Process",
                                component: sortingProcess,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/sorting/process-pdf/:Id",
                                label: "Process",
                                component: sortingProcessPdf,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                        ],
                    },

                    // {
                    //   path: "/innersorting",
                    //   label: "Invoice",
                    //   // label: "InnerSorting",
                    //   component: innerSorting,
                    //   sideMenu: false,
                    //	roles: [ 'Guard', 'Manager', 'Admin'],
                    //   routes:[
                    //     {
                    //       path: "/process/:Id",
                    //       label: "Process",
                    //       component: process,
                    //       sideMenu: false,
                    //		  roles: [ 'Guard', 'Manager', 'Admin'],
                    //     }
                    //   ]
                    // },
                    // {
                    //   path: "/material-wise-innersorting",
                    //   // label: "MaterialWiseInnerSorting",
                    //   label: "Material-Wise",
                    //   component: materialWiseInnerSorting,
                    //   sideMenu: false,
                    //	roles: [ 'Guard', 'Manager', 'Admin'],
                    //   routes:[
                    //     {
                    //       path: "/material-wise-process",
                    //       label: "Process",
                    //       component: materialWiseProcess,
                    //       sideMenu: false,
                    //		roles: [ 'Guard', 'Manager', 'Admin'],
                    //     },
                    //   ]
                    // }
                ],
            },
            {
                path: "/baling",
                label: "Baling",
                component: baling,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["10.1", "10.1.2"],
                routes: [
                    {
                        path: "/baling-process",
                        label: "Baling Process",
                        component: balingProcessList,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                        routes: [
                            {
                                path: "/processbaling",
                                label: "Process",
                                component: processbaling,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/processbaling/:Id",
                                label: "Process",
                                component: processBalingView,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/processbaling-pdf/:Id",
                                label: "Process",
                                component: processBalingViewPdf,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                        ],
                    },
                ],
            },
            {
                path: "/lumping",
                label: "Lumping",
                component: lumping,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["10.2", "10.2.2"],
                routes: [
                    {
                        path: "/lumping-process",
                        label: "Lumping Process",
                        component: lumpingProcessList,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                        routes: [
                            {
                                path: "/processlumping",
                                label: "Process",
                                component: processLumping,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/processlumping/:id",
                                label: "Process",
                                component: processLumpingView,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                            {
                                path: "/processlumping-pdf/:id",
                                label: "Process",
                                component: processLumpingViewPdf,
                                sideMenu: false,
                                roles: ["Guard", "Manager", "Admin"],
                            },
                        ],
                    },
                ],
            },
            // {
            //   path: "/process/:Id",
            //   label: "Process",
            //   component: process,
            //   sideMenu: false,
            //	 roles: [ 'Guard', 'Manager', 'Admin'],
            // },
            // {
            //   path: "/material-wise-process",
            //   label: "MaterialWiseProcess",
            //   component: materialWiseProcess,
            //   sideMenu: false,
            //   roles: [ 'Guard', 'Manager', 'Admin'],
            // },
            // {
            //   path: "/innersorting",
            //   label: "InnerSorting",
            //   component: innerSorting,
            //   sideMenu: false,
            //   roles: [ 'Guard', 'Manager', 'Admin'],
            // },
            // {
            //   path: "/material-wise-innersorting",
            //   label: "MaterialWiseInnerSorting",
            //   component: materialWiseInnerSorting,
            //   sideMenu: false,
            //   roles: [ 'Guard', 'Manager', 'Admin'],
            // },
            {
                path: "/materialwise",
                label: "MaterialWise",
                component: MaterialWise,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "/outward",
        label: "Outward",
        component: outward,
        icon: outwardIcon,
        sideMenu: true,
        roles: ["Guard", "Manager", "Admin"],
        menu_id: "9",
        permissions: ["9.1", "9.1.2"],
        routes: [
            {
                path: "/add-outward",
                label: "Add Transaction",
                component: addOutward,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/outward/outward-details/:id",
                label: "Outward-Details",
                component: viewOutwrdDetails,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/outward/outward-details-pdf/:id",
                label: "Outward-Details",
                component: viewOutwrdDetailsPdf,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "/inventory",
        label: "Inventory",
        component: inventory,
        icon: inventoryIcon,
        sideMenu: true,
        menu_id: "3",
        roles: ["Guard", "Manager", "Admin"],
        permissions: ["3.1", "3.1.2"],
        routes: [
            {
                path: "/sorted-inventory",
                label: "Sorted Inventory",
                component: sortedinventory,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "/expenses-management",
        label: "Expenses Management",
        component: ExpenseManagment,
        icon: expenseIcon,
        sideMenu: true,
        menu_id: "11",
        roles: ["Guard", "Manager", "Admin"],
        permissions: ["11.1", "11.1.2"],
        routes: [
            {
                path: "/expenses-management/expense-list",
                label: "Expense-list",
                component: expenseList,
                sideMenu: false,
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "#/",
        label: "Attendance",
        icon: attendanceIcon,
        component: "",
        sideMenu: true,
        menu_id: "2",
        roles: ["Guard", "Manager", "Admin"],
        permissions: ["2.1", "2.1.2"],
        routes: [
            {
                path: "/staff",
                label: "Staff",
                component: asyncUnderConstruction,
                sideMenu: true,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "/reports",
        label: "Reports",
        component: Reports,
        icon: reportIcon,
        sideMenu: true,
        menu_id: "7",
        roles: ["Guard", "Manager", "Admin"],
        permissions: ["7.1", "7.1.2"],
        routes: [
            {
                path: "/inwardReport",
                label: "Inward",
                component: inwardReports,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/outwardReport",
                label: "Outward",
                component: outwardReports,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/sortingReport",
                label: "Sorting",
                component: sortingReports,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/balingReport",
                label: "Baling",
                component: balingReports,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/lumpingReport",
                label: "Lumping",
                component: lumpingReports,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
            {
                path: "/expensesReport",
                label: "Expenses",
                component: asyncUnderConstruction,
                sideMenu: false,
                permissions: [],
                roles: ["Guard", "Manager", "Admin"],
            },
        ],
    },
    {
        path: "#/",
        label: "Register and Manage",
        icon: registerIcon,
        component: "",
        sideMenu: true,
        menu_id: "6",
        roles: ["Guard", "Manager", "Admin"],
        permissions: ["6.1", "6.2", "6.3", "6.1.2", "6.2.2", "6.3.2"],
        routes: [
            {
                path: "/customer",
                label: "Customer",
                component: customers,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["6.1", "6.1.2"],
                routes: [
                    {
                        path: "/customer/customer-details-edit/:id",
                        label: "Customer-details",
                        component: customerDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/customer/customer-details-view/:id",
                        label: "Customer-details",
                        component: customerViewDetails,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/customer/customer-details-view-Pdf/:id",
                        label: "Customer-details",
                        component: customerViewDetailsPdf,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            {
                path: "/vendor",
                label: "Vendor",
                component: vendors,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["6.3", "6.3.2"],
                routes: [
                    {
                        path: "/vendor/vendor-details-edit/:id",
                        label: "Vendor-details",
                        component: vendorEditDetail,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/vendor/vendor-details-view/:id",
                        label: "Vendor-details",
                        component: vendorViewDetail,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/vendor/vendor-details-view-pdf/:id",
                        label: "Vendor-details",
                        component: vendorViewDetailPdf,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            {
                path: "/transporter",
                label: "Transporter",
                component: transporter,
                sideMenu: true,
                roles: ["Guard", "Manager", "Admin"],
                permissions: ["6.2", "6.2.2"],
                routes: [
                    {
                        path: "/transporter/transporter-details/:id",
                        label: "Transporter-details",
                        component: transporterDetail,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                    {
                        path: "/transporter/transporter-details-pdf/:id",
                        label: "Transporter-details",
                        component: transporterDetailPdf,
                        sideMenu: false,
                        roles: ["Guard", "Manager", "Admin"],
                    },
                ],
            },
            // {
            //   path: "/requisition",
            //   label: "Requisition",
            //   component: asyncPageNotFound,
            //   sideMenu:true,
            // },
        ],
    },
    {
        path: "/alert-notifications",
        label: "Alert and Notification",
        component: asyncUnderConstruction,
        icon: alertIcon,
        sideMenu: true,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    {
        path: "/helpdesk",
        label: "Help Desk",
        component: asyncUnderConstruction,
        icon: helpIcon,
        sideMenu: true,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    {
        path: "/administration",
        label: "Administration",
        component: asyncUnderConstruction,
        sideMenu: false,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    {
        path: "/profile",
        label: "Profile",
        component: Profile,
        sideMenu: false,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    // {
    //   path: "/facility",
    //   label: "Facility Management",
    //   component: asyncUnderConstruction,
    //   sideMenu: false,
    //	roles: [ 'Guard', 'Manager', 'Admin'],
    // },
    {
        path: "/logout",
        label: "Logout",
        component: Logout,
        sideMenu: false,
        roles: ["Guard", "Manager", "Admin"],
        permissions: [],
    },
    //other routes
    // {
    //   path: "/transporter-details/:id",
    //   label: "",
    //   component: transporterDetail,
    //   sideMenu:false,
    // },
];

//----------------backup prevoius routes------------
/*
  [
	{
	  path: "/",
	  label: "Dashboard",
	  component: dashboard,
	  icon:Ico1,
	  sideMenu:false,
	},
	{
	  path: "/dashboard",
	  label: "Dashboard",
	  component: dashboard,
	  icon:Ico1,
	  sideMenu:true,
	},
	{
	  path: "/inward",
	  label: "Inward",
	  component: inward,
	  icon:Ico1,
	  sideMenu:true,
	  routes: [
		{
		  path: "/add-inward",
		  label: "Add Inward",
		  component: addInward,
		  sideMenu:false,
		}]
	},
	{
	  path: "/profile",
	  label: "Profile",
	  component: null,
	  icon:Ico2,
	  sideMenu:true,
	},
	{
	  path: "#/",
	  label: "Admin",
	  icon:Ico4,
	  component:"" ,
	  routes: [
		{
		  path: "/facility",
		  label: "Facility Management",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/organization",
		  label: "Organization",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/materials",
		  label: "Material Management",
		  component: materials,
		  sideMenu:true,
		},

		{
		  path: "/report",
		  label: "Report Management",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/expenses-management",
		  label: "Expenses Management",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/notifications",
		  label: "Alert & Notifications",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/process-management",
		  label: "Process Management",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/staff",
		  label: "Staff",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
	  ]
	},
	{
	  path: "#/",
	  label: "Register and Manage",
	  icon:Ico4,
	  component:"" ,
	  routes: [
		{
		  path: "/customer",
		  label: "Customer",
		  component: customers,
		  sideMenu:true,
		  routes:[
			{
			  path: "/customer/customer-details-edit/:id",
			  label: "Customer-details",
			  component: customerDetails,
			  sideMenu:false,
			},
			{
			  path: "/customer/customer-details-view/:id",
			  label: "Customer-details",
			  component: customerViewDetails,
			  sideMenu:false,
			}
		  ]
		},
		{
		  path: "/vendor",
		  label: "Vendor",
		  component: vendors,
		  sideMenu:true,
		  routes:[
			  {
				path: "/vendor/vendor-details-edit/:id",
				label: "Vendor-details",
				component: vendorEditDetail,
				sideMenu:false,
			  },
			  {
				path: "/vendor/vendor-details-view/:id",
				label: "Vendor-details",
				component: vendorViewDetail,
				sideMenu:false,
			  }
			]
		},
		{
		  path: "/transporter",
		  label: "Transporter",
		  component: transporter,
		  sideMenu:true,
		  routes:[
			{
			  path: "/transporter/transporter-details/:id",
			  label: "Transporter-details",
			  component: transporterDetail,
			  sideMenu:false,
			}
		  ]
		},

		{
		  path: "/requisition",
		  label: "Requisition",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
	  ]
	},
	{
	  path: "#/",
	  label: "Material Management",
	  icon:Ico4,
	  component:asyncPageNotFound ,
	  sideMenu:true,
	  routes: [
		{
		  path: "/sorting",
		  label: "Sorting",
		  component: Sorting,
		  sideMenu:true,
		},
		{
		  path: "/process",
		  label: "Process",
		  component: process,
		  sideMenu:false,
		},
		{
		  path: "/innersorting",
		  label: "InnerSorting",
		  component: innerSorting,
		  sideMenu:false,
		},
		{
		  path: "/materialwise",
		  label: "MaterialWise",
		  component: MaterialWise,
		  sideMenu:false,
		},
		{
		  path: "/baling",
		  label: "Baling",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/lumping",
		  label: "Lumping",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/outward",
		  label: "Outward",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
		{
		  path: "/inventory-at-hand",
		  label: "Inventory at hand",
		  component: asyncPageNotFound,
		  sideMenu:true,
		},
	  ]
	},
	{
	  path: "/attendance",
	  label: "Attendance",
	  component: asyncPageNotFound,
	  icon:Ico3,
	  sideMenu:true,
	  routes: []
	},
	{
	  path: "/reports",
	  label: "Reports",
	  component: asyncPageNotFound,
	  icon:Ico3,
	  sideMenu:true,
	  routes: []
	},
	{
	  path: "/logout",
	  label: "Logout",
	  component: Logout,
	  icon:logOut,
	  sideMenu:true,
	  routes: []
	},
	//other routes
	// {
	//   path: "/transporter-details/:id",
	//   label: "",
	//   component: transporterDetail,
	//   sideMenu:false,
	// },
  ];
  */
