import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
    ToastContainer,
    Slide, //Zoom, Flip, Bounce
} from "react-toastify";
import Spinner from "./components/UI/Spinner/Spinner";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import Layout from "./hoc/Layout/Layout";

import { AuthenticatedRoutes } from "./routes/routes";
import { generateAppRoutes } from "./shared/utility";

import PrivateRoute from "./routes/PrivateRoute";
//import * as actions from './store/actions/index';
import "./App.scss";

const asyncAuth = asyncComponent(() => {
    return import("./containers/Auth/Login");
});

const asyncAuthOtp = asyncComponent(() => {
    return import("./containers/Auth/OtpVerfication");
});

const asyncPageNotFound = asyncComponent(() => {
    return import("./components/PageNotFound");
});

class App extends Component {
    componentDidMount() {
        if (this.props.isAuthenticated === false) {
            return <Redirect to="/" />;
        }
    }
    render() {
        let routes = (
            <Switch>
                <Route path="/otpverify" component={asyncAuthOtp} />
                <Route path="/" exact component={asyncAuth} />
                <Route path="*" component={asyncPageNotFound}></Route>
                <Redirect to="/" />
            </Switch>
        );

        if (this.props.isAuthenticated) {
            //let pathname = this.props.location.pathname !== '/' ? this.props.location.pathname : '/dashboard';
            //for dynamic routes
            let allroutes = generateAppRoutes(AuthenticatedRoutes).map(
                (route) => (
                    <Route
                        path={route.path}
                        exact
                        component={route.component}
                        key={route.path}
                    />
                )
            );

            routes = (
                <PrivateRoute>
                    <Switch>
                        {allroutes}
                        <Route
                            path="*"
                            exact={true}
                            component={asyncPageNotFound}
                        />
                        {/* <Route path="/" component={asyncAuth} />
						<Route path="*" component={asyncPageNotFound} />
						<Redirect to={pathname}/>
						*/}
                    </Switch>
                </PrivateRoute>
            );
        }

        return (
            <div>
                <>
                    {this.props.isAuthenticated === true ? (
                        <Layout {...this.props}> {routes} </Layout>
                    ) : (
                        <div>{routes}</div>
                    )}
                    <ToastContainer
                        position="bottom-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        limit={1}
                        transition={Slide}
                    />
                    <Spinner />
                </>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated,
    };
};

export default withRouter(connect(mapStateToProps, null)(App));
