import React from "react";
import PropTypes from "prop-types";
import { Modal} from "react-bootstrap";

import Button from '../Button/Button';

const Modelwrapper = (props) => {
    let customStyle = (props.ischildModel && props.isPopUpShow) ? props.custmStyles : null
    return(
        <>
        {props.ischildModel && props.isPopUpShow ? <div className="selfChildModelFade" ></div> : null}
        <Modal
            show={props.isPopUpShow}
            size={props.size}
            onHide={() => props.modelIdentityKey ? 
                props.toggleModel(props.modelIdentityKey) : props.toggleModel()
            }
            className="custom-modal"
            centered
            backdrop="static"
            style={customStyle}
        >
        <Modal.Header closeButton>
            <Modal.Title> {props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Button
                variant="secondary"
                clicked={() => props.modelIdentityKey ? 
                    props.toggleModel(props.modelIdentityKey) : props.toggleModel()}
                className=""
            > {props.cancleBtnTitle? props.cancleBtnTitle : "Close"}
            </Button>
            <Button
                variant="danger"
                clicked={props.onSave}
                className=""
                disabled = {props.disabled? props.disabled: false}
            >{props.saveBtnTitle}
            </Button>
            {props.resetBtn &&
            <Button
                variant="secondary"
                clicked={props.resetFilter}
                className=""
                disabled = {props.disabled? props.disabled: false}
            >{props.resetBtnTitle}
            </Button>}
        </Modal.Footer>
    </Modal></>
    );
};

//-----------Proptype typechecking------------

Modelwrapper.propTypes = {
    heading: PropTypes.string.isRequired,
    isPopUpShow: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    toggleModel: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    saveBtnTitle: PropTypes.string.isRequired,
}

export default Modelwrapper;
