import * as actionTypes from './actionTypes';
import axiosApi from '../../api';
import { showLoader } from "./applicationAction";
import { hideLoader } from "./applicationAction";
import * as materialsApi from '../../shared/Api/materialManagement';
import { ALERT_ERROR, ALERT_WARNING } from '../../shared/constants/constants';
import { ToastAlert} from '../../shared/utility';

export const addMaterial = ( data ) => {
    return {
        type: actionTypes.ADD_MATERIAL,
        data: data
    };
};

export const updateMaterial = ( data, id) => {
    return {
        type: actionTypes.UPDATE_MATERIAL,
        data: data,
        id: id
    };
};

export const removeMaterial = ( data ) => {
    return {
        type: actionTypes.REMOVE_MATERIAL,
        data: data
    };
};

export const setMaterials = ( materials ) => {
    return {
        type: actionTypes.SET_MATERIALS,
        materials: materials
    };
};

export const fetchMaterialsFailed = (error) => {
    return {
        type: actionTypes.FETCH_MATERIALS_FAILED,
        errormsg: error.message
    };
};

export const removeMaterialsFailed = (error) => {
    return {
        type: actionTypes.FETCH_MATERIALS_FAILED,
        errormsg: error.message
    };
};

export const initMaterials = () => {
    return dispatch => {
        dispatch(showLoader());
        let url = materialsApi.GET_ALL_MATERIALS_LIST;
        axiosApi.getDataRequest(url)
            .then( response => {
                let result = response.data;
                if(response.status === 200){
                    dispatch(setMaterials(result.data));
                    dispatch(hideLoader());
                }else{
                    ToastAlert({type:ALERT_WARNING, msg:response.data.message});
                    dispatch(fetchMaterialsFailed({message: response.data.message}));
                    dispatch(hideLoader());
                }
            })
            .catch( error => {
                dispatch(fetchMaterialsFailed(error));
                dispatch(hideLoader());
            })
    };
};
