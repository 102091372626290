import axiosApi from "../../api";
import { showLoader, hideLoader } from "./applicationAction";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../shared/constants/constants";
import * as verificationApi from "../../shared/Api/verificationManagement";
import {
    ToastAlert,
    getServerError,
    putVariableInString,
    //randomStringGenrator,
} from "../../shared/utility";

var setStatus = (res) => {
    var statusPalette = {
        VALID : "Verified",
        INVALID : "Not Verified"
    };
    return statusPalette[res] || "Pending";
}

export const panVerify = (pan) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(verificationApi.PAN_VERIFY);
        return axiosApi.getDataRequest(url, {Pan: pan})
            .then(response => {
                console.log("pan verify response : => ",response)
                let result = response.data?.data?.data?.status;
                if (response.status === 200 && result === "VALID") {
                    ToastAlert({ type: ALERT_SUCCESS, msg: response.data.message });
                    dispatch(hideLoader());
                    return setStatus(result);
                } else if(response.status === 200 && result === "INVALID") {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
                    dispatch(hideLoader());
                    return setStatus(result);
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.data.message });
                    dispatch(hideLoader());
                    return setStatus("Invalid Pan pattern")
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
};

export const ifscVerify = (ifscCode) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(verificationApi.IFSC_VERIFY);
        return axiosApi.getDataRequest(url, {Ifsc: ifscCode})
            .then(response => {
                console.log("Ifsc verify response : => ",response)
                let msg = response?.data?.message;
                let status = response?.data?.status;
                let data = response?.data?.data;
                if (response.status === 200 && status === "VALID") {
                    ToastAlert({ type: ALERT_SUCCESS, msg: msg });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : data
                    };
                    return obj;
                } else if (response.status === 200 && status === "INVALID") {
                    ToastAlert({ type: ALERT_ERROR, msg: msg });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : "Not Found"
                    };
                    return obj;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: msg });
                    dispatch(hideLoader());
                    return setStatus("Invalid Ifsc pattern")
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
};

export const upiVerify = (upi) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(verificationApi.UPI_VERIFY);
        return axiosApi.getDataRequest(url, {Upi: upi})
            .then(response => {
                console.log("Upi verify response : => ",response)
                let msg = response?.data?.message;
                let status = response?.data?.status;
                let data = response?.data?.data;
                if (response.status === 200 && status === "VALID") {
                    ToastAlert({ type: ALERT_SUCCESS, msg: response.data?.message });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : data
                    };
                    return obj;
                } else if (response.status === 200 && status === "INVALID") {
                    ToastAlert({ type: ALERT_ERROR, msg: msg });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : "Not Found"
                    };
                    return obj;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: msg });
                    dispatch(hideLoader());
                    return setStatus("Invalid Upi pattern")
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
};

export const gstVerify = (gstin) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(verificationApi.GSTIN_VERIFY);
        return axiosApi.getDataRequest(url, {Gstin: gstin})
            .then(response => {
                console.log("Gstin verify response : => ",response.data.data)
                let msg = response?.data?.message;
                let status = response?.data?.status;
                let data = response?.data?.data;
                if (response.data.data.code === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: msg });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : data
                    };
                    return obj;
                } else if (response.data.data.code === 200 || response.data.data.message === "No records found" || response.data.data.message === "Invalid GSTIN / UID") {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.data.message });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : "Not Found"
                    };
                    return obj;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.data.message });
                    dispatch(hideLoader());
                    return setStatus("Invalid Gstin pattern")
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
};

export const bankAcVerify = (accountNumber,ifscCode) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(verificationApi.BANK_AC_VERIFY);
        return axiosApi.getDataRequest(url, {AccountNo: accountNumber, Ifsc: ifscCode})
            .then(response => {
                console.log("BankAC verify response : => ",response.data.data)
                let msg = response?.data?.message;
                let status = response?.data?.status;
                let data = response?.data?.data;
                if (response.data.data.code === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: msg });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : data
                    };
                    return obj;
                } else if (response.data.data.code === 200 || response.data.data.message === "No records found" || response.data.data.message === "Invalid GSTIN / UID") {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.data.message });
                    dispatch(hideLoader());
                    let obj = {
                        status : setStatus(status),
                        data : "Not Found"
                    };
                    return obj;
                } else {
                    ToastAlert({ type: ALERT_ERROR, msg: response.data.data.message });
                    dispatch(hideLoader());
                    return setStatus("Invalid Bank Account")
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
};