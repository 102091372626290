import * as actionTypes from '../actions/actionTypes';
import { 
    objDeepClone,
    updateObject,
} from '../../shared/utility';

const initialCustomerOptn = [{ 
    label: 'Select Customer Name', 
    value: '', isActive: false  
}];

const intialExpensesOptn = [{ 
    label: 'Select Expense Name', 
    value: '', isActive: false
}];

const intialMatlOptn = [{ 
    label: 'Select Material Type', 
    value: '', isActive: false  
}];

const initialState = {
    customerArray: [],
    materialsArray:[],
    processNumberOptions:[],
    customerOptionsArray: objDeepClone(initialCustomerOptn),
    materialOptionsArray: objDeepClone(intialMatlOptn),
    invoiceNumberExpenses: '',
    outwardsList:[],
    ExpensesTypes:[],
    ExpensesTypesOptionsArray:intialExpensesOptn,
    outwardId:'',
    RatingData:[],
    ExpansesTypesArray:[],
    outwardViewData:[],
    outwardIndirectTransaction:[],
    outwardRatingUpdateData:[],
    outwardMatlList:[],
    totalInvoicedQty: 0,
    showSendInvoiceModal: false,
    showExpensesModal: false,
    showRatingModal: false,
    isRedirect: false
};

const  toggleOutwardInvoiceSenModel = (state, action) => {
    return  {...state, 
        showSendInvoiceModal:!state.showSendInvoiceModal,
        // showExpensesModal: !state.showSendInvoiceModal === false ? true : false
        showRatingModal: !state.showSendInvoiceModal === false ? true : false
    };
}

const toggleOutwardExpensesModel = (state, action) => {
    return  {...state, 
        showExpensesModal: !state.showExpensesModal,
        //invoiceNumberExpenses: state.invoiceNumberExpenses === '' ? action?.data?.invoiceNumber : state.invoiceNumberExpenses,
        outwardId:state.outwardId === '' ? action?.data?.outwardId : state.outwardId,
        // showRatingModal: !state.showExpensesModal === false ? true : false,
        showSendInvoiceModal: !state.showExpensesModal === false ? true : false,
    }
}

const toggleOutwardRatingModel= (state, action) => {
    let Obj = {};
    if(!state.showRatingModal === false){
        Obj.invoiceNumberExpenses = null;
        Obj.outwardId = null;
        Obj.isRedirect = true;
    }else{
        Obj.isRedirect = false;
    }
    Obj.showRatingModal = !state.showRatingModal
    return  {...state, 
        // Obj,
        showRatingModal: !state.showRatingModal,
        isRedirect: !state.showRatingModal === false ? true : false,
    };
}

const changeRedirect= (state, action) => {
    return  {
        ...state, 
        isRedirect: false, 
        invoiceNumberExpenses: '',
        outwardId : '',
        RatingData:[],
        ExpansesTypesArray:[],
    };
}

const setOutwardList = ( state, action ) => {
    return  {
        ...state, 
        outwardsList:action.data,
        totalInvoicedQty: action.totalInvoicedQty
     };
};

const setOutwardExpensesTypes = ( state, action ) => {
    let new_array = action.data
    .map(m => {
        return {
                label: m.expensesName, 
                value: `${m.expensesHeadId}`,
            }
        }
    )
    return  {
        ...state, 
        ExpensesTypes:action.data,
        ExpensesTypesOptionsArray: [
            ...intialExpensesOptn,
            ...new_array
        ],
    };
};

const setOutwardExpansesTypesArray = ( state, action ) => {
    return  {
        ...state, 
        ExpansesTypesArray:action.data
    };
};

const setRatingData = ( state, action ) => {
    return  {
        ...state, 
        RatingData:action.data
     };
};


const setOutwardViewData = ( state, action ) => {
    return  {
        ...state, 
        outwardViewData:action.data
     };
};

const setOutwardIndirectTransaction = ( state, action ) => {
    return  {
        ...state, 
        outwardIndirectTransaction:action.data
    };
};

const setOutwardUpdateRatingData = ( state, action ) => {
    return  {
        ...state, 
        outwardRatingUpdateData:action.data
     };
};

const  setCustomers = ( state, action ) => {
    let new_array = action.data
    .map(d => {
        return {
                label: d.customerName, 
                value: `${d.customerId}`, 
                isActive: d.isActive,
                isGstReg: d.customerPremiseList?.[0]?.gstFlag
            }
        }
    )
    return  {
        ...state, 
        customerArray:action.data,
        customerOptionsArray: [
            ...initialCustomerOptn,
            ...new_array
        ],
        isLoading: false 
    };
};

const  setOutwardMaterials = ( state, action ) => {
    let new_array = action.data
    .map(m => {
        return {
                label: m.materialName, 
                value: `${m.materialId}`, 
                isActive: m.isActive
            }
        }
    )
    return  {
        ...state, 
        materialsArray:action.data,
        materialOptionsArray: [
            ...intialMatlOptn,
            ...new_array
        ],
        isLoading: false 
    };
};

const  setProcessNumbers = ( state, action ) => {
    return  {
        ...state,
        processNumberOptions: action.data
    }
};

const outwardReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CHANGE_REDIRECT: return changeRedirect(state, action);

        case actionTypes.OUTWARD_INVOICE_POPUP_SHOW: return toggleOutwardInvoiceSenModel(state, action);
        case actionTypes.OUTWARD_EXPENSES_POPUP_SHOW: return toggleOutwardExpensesModel(state, action);
        case actionTypes.OUTWARD_RATING_POPUP_SHOW: return toggleOutwardRatingModel(state, action);
        
        case actionTypes.SET_PROCESS_NUBMERS: return setProcessNumbers(state, action);
        case actionTypes.SET_OUTWARD_LIST: return setOutwardList(state, action);
        case actionTypes.SET_OUTWARD_VIEWDATA: return setOutwardViewData(state, action);
        case actionTypes.SET_OUTWARD_INDIRECT_TRANSACTION: return setOutwardIndirectTransaction(state, action);
        case actionTypes.SET_OUTWARD_UPDATE_RATING: return setOutwardUpdateRatingData(state, action);
        
        case actionTypes.SET_PROCESS_CUSTOMER: return setCustomers( state, action );
        case actionTypes.SET_OUTWARD_MATERIAL: return setOutwardMaterials( state, action );
        
        case actionTypes.SET_EXPENSESTYPES: return setOutwardExpensesTypes(state, action);
        case actionTypes.SET_EXPENSESTYPESARRAY: return setOutwardExpansesTypesArray(state, action);

        case actionTypes.SET_RATINGDATA: return setRatingData(state, action);

        default: return state;
    }
};

export default outwardReducer;