const url_prifix = "api/user-management";
export const GET_USERS = `${url_prifix}/users`;
export const GET_USER_INFO = `${url_prifix}/userInfo`;
export const GET_USER_BY_ID = `${url_prifix}/user/%s`;
export const GET_USER_BY_EMAIL = `${url_prifix}/user`;
export const ADD_UPDATE_USER = `${url_prifix}/user`;
export const DELETE_USER = `${url_prifix}/user/%s`;
export const CHANGE_USER_STATUS = `${url_prifix}/user/status`;
export const UPDATE_USER_PROFILE = `${url_prifix}/user/%s`;

export const GENRATE_OTP_BY_EMAIL = `${url_prifix}/sendOtpEmail`;
export const RECEIVE_OTP = `${url_prifix}/matchOtp`;