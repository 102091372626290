import React from 'react';
import {connect} from 'react-redux';

const spinner = (props) => {
  const {loading} = props;
  if(!loading) return null;
  return (
    <div className="loaderWrap">
      <ul className="loader">
        <li className="center"></li>
        <li className="item item-1"></li>
        <li className="item item-2"></li>
        <li className="item item-3"></li>
        <li className="item item-4"></li>
        <li className="item item-5"></li>
        <li className="item item-6"></li>
        <li className="item item-7"></li>
        <li className="item item-8"></li>
      </ul>
    </div>
  )
};

const mapStateToProps = state => ({ loading: state.application.loading })
export default connect(mapStateToProps)(spinner);