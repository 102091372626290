import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {AVAILABLE_LANGUAGES} from './shared/constants/constants';
const fallbackLng = ['en'];

i18n
    .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // fallback language is english.
        backend: {
            /* translation file path */
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',

        whitelist: AVAILABLE_LANGUAGES,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;
//npm install i18next react-i18next i18next-http-backend i18next-browser-languagedetector --save