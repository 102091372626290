import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index'
import { useTable, useExpanded, useGlobalFilter, usePagination } from 'react-table';
import { Table } from 'reactstrap';
import { Pagination, FormControl, InputGroup, Row, Col } from 'react-bootstrap';
import TablePaginationActions from '../../../components/TablePaginationActions';
import ProcessTableFilterCmp from '../../../components/ProcessTableFilter';

import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from '../../../components/UI/Input/Input';

import searchIco from '../../../assets/icons/search.svg';

import ButtonCmp from '../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
//------------------------------------------------------
import defaultGlobalFilter from "../../tables/GlobalFilterHelper";


const useStyles = makeStyles({
    // root: {
    //   width: '100%',
    //   //borderStyle: 'solid'
    // },
    container: {
        maxHeight: '60vh',
    },
});

const MaterialWiseSortingTable = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tableRef = React.createRef();
	const filterOptions = { filteredIds: [] };
	let { columns, data, defaultPageSize, history, shoProcessBtn} = props;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		//rows,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		state,
		gotoPage,
		pageCount,
		setPageSize,
		prepareRow,
		setFilter,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: defaultPageSize },
			// defaultColumn: { Filter: DefaultColumnFilter },
			globalFilter: (rows, columnIds, filterValue) =>
        defaultGlobalFilter(rows, columnIds, filterValue, filterOptions)
		},
		//useFilters,
		//useSortBy,
		useGlobalFilter, // useGlobalFilter!
		useExpanded,
		usePagination
	);
	const { pageIndex, pageSize, globalFilter } = state
	useEffect(() => {
		setPageSize(+defaultPageSize);
		//console.log("globalFilter", globalFilter);
	}, [globalFilter]);

	const scrollTop = () => {
		tableRef.current.scrollTop = 0;
	}

	return (
		<> 
			<h2 className="font-weight-bold">{t("Sorting")}</h2>
			<ProcessTableFilterCmp
                setFilter={setFilter}
                serchValue = {globalFilter || ""}
				changed={e => setGlobalFilter(e.target.value)}
				filterModal = {props.toggleFilterModal}
				//history = {history}
				shoProcessBtn={true} 
				btnTitle = {t("Process")}
				btnClicked = {() =>history.push(`/material-wise-process`)}
                onProcessBatchChange = {(bt) => props.setBatchingType(bt)}
                isOn= {props.batchingType === 'Invoicewise' ? false : true}
            />
			{page.length > 0 ?
				<>
					<TableContainer className={[classes.container, "mt-3 table-responsive"]}  ref={tableRef}>
						<MaUTable {...getTableProps()} stickyHeader aria-label="sticky table">
							<TableHead>
								{headerGroups.map((headerGroup) => (
									<TableRow {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => {
											return column.hideHeader === false ? null : (
												<TableCell {...column.getHeaderProps()}>
													{/* <div {...column.getSortByToggleProps()}> */}
													{column.render('Header')}
													{/* {generateSortingIndicator(column)} */}
													{/* </div> */}
													{/* <Filter column={column} /> */}
												</TableCell>
											)
										})}
									</TableRow>
								))}
							</TableHead>
							<TableBody {...getTableBodyProps()}>
								{page.map((row, i) => {
								prepareRow(row)
								return (
									<TableRow {...row.getRowProps()}>
									{row.cells.map(cell => {
										return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
									})}
									</TableRow>
								)
								})}
							</TableBody>
						</MaUTable>
					</TableContainer>
					{/* <div  className="tablepagination">
						{/* <Pagination className="float-right">
							<Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
							<Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
							<InputGroup className="mb-3">
								<InputGroup.Prepend>
									<InputGroup.Text>Page <strong> {` ${pageIndex + 1} of ${pageOptions.length} `}</strong>
									</InputGroup.Text>
									<InputGroup.Text>| Go to page:{' '}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									type='number'
									defaultValue={pageIndex + 1}
									onChange={e => {
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										gotoPage(pageNumber)
									}}
								/>
								<FormControl as="select" custom value={pageSize}
									onChange={e => setPageSize(Number(e.target.value))}>
									{[5, 10, 25, 50, 100].map(pageSize => (
										<option key={pageSize} value={pageSize}>
											Show {pageSize}
										</option>
									))}
								</FormControl>
							</InputGroup>
							<Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
							<Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
						</Pagination> }
						<span style={{lineHeight:"2"}}>Rows per page:</span>
						<select onChange={e => setPageSize(Number(e.target.value))}>
							{[10, 15, 20, 25, 50, 100].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									 {pageSize}
								</option>
							))}
						</select>
						<div >
							<span>{` ${pageIndex + 1}-${pageSize}  of ${pageOptions.length} `}</span>
							
							<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}><svg  width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" ></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></button>
							<button onClick={() => previousPage()} disabled={!canPreviousPage}><svg  width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
							<button onClick={() => nextPage()} disabled={!canNextPage}><svg  width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" >
								<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
							<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><svg  width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></button>
						</div>
					</div> */}
					<TablePaginationActions
						count = {pageOptions.length}
						pageSize = {pageSize}
						onchangePageSize = {(e) => setPageSize(Number(e.target.value))}
						onFirstPage = {() =>  gotoPage(0)}
						onPreviousPage = {() => previousPage()}
						onNextpage = {() => nextPage()}
						onLastpage = {() => gotoPage(pageCount - 1)}
						pageIndex = {pageIndex}
						canPreviousPage= {!canPreviousPage}
						canNextPage= {!canNextPage}
						scrollTop={scrollTop}
                	/>
				</>
				: <div className="text-center mt-2">{t("No Record Available")}</div>
			}
		</>
	);
};

const mapStateToProps = state => {
    return {
        batchingType: state.process.batchingType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBatchingType: (batchType) => dispatch(actions.setBatchingType(batchType)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialWiseSortingTable);