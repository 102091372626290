const url_prifix = "api/inward-management";
export const GET_INWARDS = `${url_prifix}/inwards/list`;
export const GET_INWARD_BY_ID = `${url_prifix}/inwards/%s`;
export const SAVE_INWARD = `${url_prifix}/inwards`;
export const DELETE_INWARD = `${url_prifix}/inwards/%s`;
export const SEND_INVOICE = `${url_prifix}/inwards/invoice`;
//export const INWARD_INDIRECT_TRANSACTION = `${url_prifix}/indirect-expenses`;
//export const DELETE_INWARD_INDIRECT_TRANSACTION = `${url_prifix}/inwards/indirect-expenses/%s`;
export const INWARD_ADD_RATING = `${url_prifix}/rating`;
export const CHANGE_INWARD_STATUS = `${url_prifix}/inwards/change-status/%s`;


// Add More Routes
export const GET_SORTING_INWARDS = `${url_prifix}/inwards/sorting/list`;
export const GET_SORTING_INWARDS_MATERIALS = `${url_prifix}/inwards/sorting/material/list/%s`;
export const GET_SORTING_INWARD_BY_ID = `${url_prifix}/inwards/sorting/%s`;


