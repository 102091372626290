//import axios from 'axios';
import * as actionTypes from './actionTypes';
import axiosApi from '../../api';
import { showLoader, hideLoader } from "./applicationAction";
import { ALERT_ERROR, ALERT_WARNING} from '../../shared/constants/constants';
import * as transportApi from "../../shared/Api/transportManagment";

import {
    ToastAlert, 
    getServerError,
    //randomStringGenrator,
} from '../../shared/utility';

export const setTransportDetails = (data) => {
    return {
        type: actionTypes.SET_TRANSPOTERS_DETAILS,
        data: data,
    };
};

export const setVehicleDetails = (data) => {
    return {
        type: actionTypes.SET_TRANSPORT_VEHICLE,
        data: data,
    };
};

export const setTransportBankDetails = (data) => {
    return {
        type: actionTypes.SET_TRANSPORT_BANK_DETAILS,
        data: data,
    };
};

export const getTransportDetails = () => {
    return dispatch => {
        dispatch(showLoader());
        let url = transportApi.GET_VEHICLE_LIST;
        return axiosApi.getDataRequest(url)
            .then(response => {
                let result = response.data.data;
                if (response.status === 200) {
                    dispatch(setTransportDetails(result));
                    dispatch(hideLoader());
                } else {
                    ToastAlert({ type: ALERT_WARNING, msg: response.data.message });
                    dispatch(hideLoader());
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
}

export const getVehicleDetails = () => {
    return dispatch => {
        dispatch(showLoader());
        let url = "api/vehicle";
        return axiosApi.getDataRequest(url)
            .then(response => {
                let result = response.data.data;
                if (response.status === 200) {
                    dispatch(setVehicleDetails(result));
                    dispatch(hideLoader());
                } else {
                    ToastAlert({ type: ALERT_WARNING, msg: response.data.message });
                    dispatch(hideLoader());
                }
            })
            .catch(error => {
                console.log('error', error)
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            })
    };
}
