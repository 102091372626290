import React from 'react'
import PropTypes from 'prop-types';

function ProcessSwitchButton(props) {

	//let id = label.toCamelCase();

	// (props.large == true ? switchClass += ' switch--large' : null);
	// (props.noText == true ? switchClass += ' switch--no-text' : null);
	// (props.theme == 'success' ? switchClass += ' switch--success' : null);
	return (
		<div aria-label="Switch One" className="processSwitchBtn--large--success">
			<label className="processSwitchBtn__label" htmlFor="processSwitchBtn">
				<input 
					role="switch" 
					type="checkbox" 
					className="processSwitchBtn__input" 
					id="processSwitchBtn"
					checked={props.isOn}
					onChange={(e) => props.handleToggle(e.target.checked)}
				/>
				<span className="processSwitchBtn__text" data-on={props.dataOn} data-off={props.dataOff}></span>
				<span className="processSwitchBtn__handle"></span>
			</label>
		</div>
	);
};

ProcessSwitchButton.propTypes = {
	dataOn: PropTypes.string,
	dataOff: PropTypes.string,
	theme: PropTypes.string,
	//label: PropTypes.label,
	//noText: PropTypes.bool
}

export default ProcessSwitchButton