import React from 'react';
import PropTypes from 'prop-types';
import docIco from '../../../assets/img/docs.svg'
import pdfIco from '../../../assets/img/pdf.svg'

function UploadButtonComponent(props) {
  return (
    <label className="myLabel">
      <img className="docIco" src={docIco} alt="docico" /> 
        <img className="pdfcIco" src={pdfIco} alt="pdfico" width="20" />
        <input type="file" onChange={(e) => props.changed(e, props.inputIndx, props.actionName)} 
          onClick={(e) => (e.target.value = null)} required 
          disabled={props.disabled ? props.disabled: false}
          />
      <span>&nbsp; &nbsp;Upload Document</span>
    </label>
  )
}

UploadButtonComponent.propTypes = {
  changed: PropTypes.func.isRequired,
  inputIndx:PropTypes.number
}

export default UploadButtonComponent


