const url_prifix = "api/outward-management";
export const GET_OUTWARDS = `${url_prifix}/outwards/list`;
export const GET_OUTWARD_BY_ID = `${url_prifix}/outwards/%s`;
export const SAVE_OUTWARD = `${url_prifix}/outwards`;
export const DELETE_OUTWARD = `${url_prifix}/outwards/%s`;
export const SEND_INVOICE = `${url_prifix}/outwards/invoice`;
//export const OUTWARD_INDIRECT_TRANSACTION = `${url_prifix}/indirect-expenses`;
//export const DELETE_OUTWARD_INDIRECT_TRANSACTION = `${url_prifix}/outwards/indirect-expenses/%s`;
export const OUTWARD_ADD_RATING = `${url_prifix}/rating`;
export const CHANGE_OUTWARD_STATUS = `${url_prifix}/outwards/change-status/%s`;
export const GET_AVAILABLE_QTY = `${url_prifix}/outwards/availability/list`;
export const GET_OUTWARD_TRANSFER_LIST = `${url_prifix}/outwards/transfer/list`;

// Add More Routes
export const GET_SORTING_OUTWARDS = `${url_prifix}/outwards/sorting/list`;
export const GET_SORTING_OUTWARDS_MATERIALS = `${url_prifix}/outwards/sorting/material/list`;
export const GET_SORTING_OUTWARD_BY_ID = `${url_prifix}/outwards/sorting/%s`;


