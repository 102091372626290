const url_baling_prifix = "api/baling-management";
const url_lumping_prifix = "api/lumping-management";
const url_sorting_prifix = "api/sorting-management";

//Sorting
export const GET_SORTING_LIST = `${url_sorting_prifix}/sorting/list`;
export const GET_SORTING_BY_ID = `${url_sorting_prifix}/sorting/%s`;
export const GET_SORTING_MATERIAL_QTY = `${url_sorting_prifix}/sorting/sorting-material/getQty`;

//Baling
export const GET_BALING = `${url_baling_prifix}/baling/list`;
export const GET_BALING_BY_ID = `${url_baling_prifix}/baling/%s`;
export const SAVE_BALING_INVOICE = `${url_baling_prifix}/baling/invoice-wise`;
export const SAVE_BALING_MATERIAL = `${url_baling_prifix}/baling/material-wise`;
export const UPDATE_BALING_INVOICE = `${url_baling_prifix}/baling/invoice-wise/%s`;
export const UPDATE_BALING_MATERIAL = `${url_baling_prifix}/baling/material-wise/%s`;
export const UPDATE_BALING_STATUS = `${url_baling_prifix}/baling/change-status/%s`;

//Lumping
export const GET_LUMPING = `${url_lumping_prifix}/lumping/list`;
export const GET_LUMPING_BY_ID = `${url_lumping_prifix}/lumping/%s`;
export const SAVE_LUMPING_INVOICE = `${url_lumping_prifix}/lumping/invoice-wise`;
export const SAVE_LUMPING_MATERIAL = `${url_lumping_prifix}/lumping/material-wise`;
export const UPDATE_LUMPING_INVOICE = `${url_lumping_prifix}/lumping/invoice-wise/%s`;
export const UPDATE_LUMPING_MATERIAL = `${url_lumping_prifix}/lumping/material-wise/%s`;
export const UPDATE_LUMPING_STATUS = `${url_lumping_prifix}/lumping/change-status/%s`;
