const url_prifix = "api/facility-management";
export const GET_INFORMATION_BY_PINCODE = `api/pincode/`;
// export const GET_ALL_FACILITY_LIST = `${url_prifix}/allFacilities`;
export const GET_ALL_FACILITY_LIST = `${url_prifix}/v1/allFacilities`;
export const GET_FACILITY_LIST = `${url_prifix}/facility`;
export const GET_FACILITY_BY_ID = `${url_prifix}/facility/%s`;
export const SAVE_FACILITY = `${url_prifix}/facility`;
export const GET_ALL_FACILITY_NAMES = `${url_prifix}/facility/names/list`;
export const GET_CLIENT_DATA = `api/client-management/defaultClient/`
export const GET_FACILITY_UTILITY = `${url_prifix}/utilities`;
export const GET_FACILITY_EQUIPMENT= `${url_prifix}/safetyEquipments`;
export const CHANGE_FACILITY_STATUS = `${url_prifix}/facility/change-status/%s`;
export const GET_FACILITY_MACHINES_AND_STAFF_BY_ID = `${url_prifix}/facility/process/staffandmachines`;

