import * as actionTypes from '../actions/actionTypes';

const initialState = {
    vendorId:null,
    vendorDetailsObj: {},
    materials:[],
    materialOptions:[],
    subMaterialOptns: [],
    vendorsList:[],
};

const clearData = ( state, action ) => {
    return {
        ...state,
        vendorId:null,
        socialSecurityArray:[],
        socialSecurityList:[],
        vendorMatlList:[],
        vendorPremiseList:[],
        vendorPremiseCPList:[],
    }
};

const setMaterials = ( state, action ) => {
    return {
        ...state,
        materials: action.materials
    };
};

const setMaterialOptns = ( state, action ) => {
    return {
        ...state,
        materialOptions: state.materials.map(d => {
            return { value: d.materialId, label: d.materialName, isdisabled: !d.isActive }
        })
    };
};

const setVendor = (state, action) => {
    return {
        ...state,
        vendorsList: action.vendors,
    };
};

const setVendorDetails = ( state, action ) => {
    let data = action.details;
    
    return {
        ...state,
        vendorId: data.vendorId,
        vendorDetailsObj: Object.keys(data).length > 0 ? data : {}
    }
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CLEAR_DATA: return clearData(state, action);
       
        case actionTypes.SET_MATERIALS: return setMaterials( state, action );
        case actionTypes.SET_MATERIALS_OPTIONS: return setMaterialOptns( state, action );

        case actionTypes.SET_VENDORS: return setVendor(state, action);
        case actionTypes.SET_VENDOR_DETAILS: return setVendorDetails(state, action);
        
        default: return state;
    }
};

export default reducer;