import * as actionTypes from "./actionTypes";
import axiosApi from "../../api";
import axios from "axios";
import { showLoader, hideLoader } from "./applicationAction";
import {
    ALERT_WARNING,
    ALERT_ERROR,
    ALERT_SUCCESS,
} from "../../shared/constants/constants";
import * as facilityApi from "../../shared/Api/facilityManagement";

import {
    ToastAlert,
    getServerError,
    putVariableInString,
    //randomStringGenrator,
} from "../../shared/utility";

//utility Option
const setUtilityOptions = (data) => {
    return {
        type: actionTypes.SET_FACILITY_UTILITY_OPTIONS,
        data: data,
    };
};

//equipment Option
const setEquipmentOptions = (data) => {
    return {
        type: actionTypes.GET_FACILITY_EQUIPMENT_OPTIONS,
        data: data,
    };
};

export const setFacilityDetails = (data) => {
    return {
        type: actionTypes.SET_FACILITY_DETAILS,
        data: data,
    };
};

export const setFacilityStaffDetails = (data) => {
    return {
        type: actionTypes.SET_FACILTIY_STAFF_DETAILS,
        data: data,
    };
};

export const setFacilityEditData = (data) => {
    return {
        type: actionTypes.SET_FACILTIY_EDIT_DETAILS,
        data: data,
    };
};

const getUtilityList = () => {
    let url = facilityApi.GET_FACILITY_UTILITY;
    return axiosApi.getDataRequest(url);
};

const getEquipmentList = () => {
    let url = facilityApi.GET_FACILITY_EQUIPMENT;
    return axiosApi.getDataRequest(url);
};
// Call Multiple Api using axios
export const getFacilityUtilityOptions = () => {
    const requestOne = getUtilityList();
    const requestTwo = getEquipmentList();
    return (dispatch) => {
        dispatch(showLoader());
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    // use/access the results
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    // console.log('getUtilityList responseOne',responseOne.data)
                    // console.log('getEquipmentList responseTwo',responseTwo.data)
                    dispatch(setUtilityOptions(responseOne.data));
                    dispatch(setEquipmentOptions(responseTwo.data));
                    dispatch(hideLoader());
                })
            )
            .catch((errors) => {
                console.log("error", errors);
                let message = getServerError(errors);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
                // react on errors.
            });
    };
};

// get All Facilities
export const getAllFacilities = (filterData) => {
    let filterDefaultData = {
        state: "",
        city: "",
        facilityOffice: "",
        OwnershipStatus: "",
    };
    let postData = filterData !== undefined ? filterData : filterDefaultData;
    return (dispatch) => {
        dispatch(showLoader());
        let url = facilityApi.GET_ALL_FACILITY_LIST;
        axiosApi
            .postDataRequest(url, postData)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200 && response.data.success === true) {
                    dispatch(setFacilityDetails(result));
                    dispatch(hideLoader());
                } else {
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// get facility by processType
export const getFaciliityByProcessType = (processName, fcltyId) => {
    return (dispatch) => {
        let dataArray = [];
        if (fcltyId) {
            dispatch(showLoader());
            //let url = facilityApi.GET_FACILITY_LIST;
            let url = facilityApi.GET_FACILITY_MACHINES_AND_STAFF_BY_ID;
            axiosApi
                .getDataRequest(url, {
                    processType: processName,
                    facilityId: fcltyId,
                })
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data.success === true
                    ) {
                        let result = response.data.data;
                        if (Object.keys(result).length > 0) {
                            dataArray["facilityMachines"] =
                                result?.facilityMachines;
                            dataArray["facilityStaffs"] = result?.staff;
                            dispatch(setFacilityStaffDetails(dataArray));
                            dispatch(hideLoader());
                        } else {
                            dataArray["facilityMachines"] = [];
                            dataArray["facilityStaffs"] = [];
                            dispatch(setFacilityStaffDetails(dataArray));
                            ToastAlert({
                                type: ALERT_ERROR,
                                msg: response.data.message,
                            });
                            dispatch(hideLoader());
                        }
                    } else {
                        dataArray["facilityMachines"] = [];
                        dataArray["facilityStaffs"] = [];
                        dispatch(setFacilityStaffDetails(dataArray));
                        //ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
                        dispatch(hideLoader());
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    let message = getServerError(error);
                    ToastAlert({ type: ALERT_ERROR, msg: message });
                    dispatch(hideLoader());
                });
        } else {
            dataArray["facilityMachines"] = [];
            dataArray["facilityStaffs"] = [];
            dispatch(setFacilityStaffDetails(dataArray));
        }
    };
};

//save facility
export const saveFacility = (dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = facilityApi.SAVE_FACILITY;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    //dispatch(showModalsPopup('sendInvoice'));
                    //dispatch(showModalsPopup('expenses',{invoiceNumber:invoiceNumber,inwardId:result.data[0].inwardId}));
                    dispatch(getAllFacilities());
                    history.push("/facility-registration");
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// Facility By id
export const getFacilityById = (id) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(facilityApi.GET_FACILITY_BY_ID, id);
        return axiosApi
            .getDataRequest(url)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200) {
                    // dispatch(setInwardViewdata(result));
                    // dispatch(setinwardIndirectTransaction(result.inwardIndirectTransaction));
                    // dispatch(setInwardMaterialRating(result.inwardMaterialRating));
                    dispatch(setFacilityEditData(result));
                    dispatch(hideLoader());
                    return result;
                } else {
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

// facility status
export const updateFacilityStatus = (status, id) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(facilityApi.CHANGE_FACILITY_STATUS, id);
        axiosApi
            .putDataRequest(url, { isActive: status })
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(getAllFacilities());
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                }
                dispatch(hideLoader());
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};
