import axios from "axios";
import * as actionTypes from "./actionTypes";
import axiosApi from "../../api";
import { showLoader, hideLoader } from "./applicationAction";
import {
    ALERT_ERROR,
    ALERT_SUCCESS,
    ALERT_WARNING,
} from "../../shared/constants/constants";
import * as vendorApi from "../../shared/Api/vendorManagement";
import * as expenseApi from "../../shared/Api/expenseManagement";
import * as materialsServices from "../../shared/Api/materialManagement";
import * as inwardApi from "../../shared/Api/inwardManagement";
import {
    ToastAlert,
    getServerError,
    putVariableInString,
    //dateCustomFormat,
    //removeLastComma,
    //randomStringGenrator,
} from "../../shared/utility";

export const setInwardList = (data, totalRecievedQty, totalInvoicedQty) => {
    return {
        type: actionTypes.SET_INWARD_LIST,
        data: data,
        totalRecievedQty: totalRecievedQty,
        totalInvoicedQty: totalInvoicedQty,
    };
};

export const setExpansesTypes = (data) => {
    return {
        type: actionTypes.SET_EXPENSESTYPES,
        data: data,
    };
};

export const setExpansesTypesArray = (data) => {
    return {
        type: actionTypes.SET_EXPENSESTYPESARRAY,
        data: data,
    };
};

export const setRatingData = (data) => {
    return {
        type: actionTypes.SET_RATINGDATA,
        data: data,
    };
};

export const setInwardViewdata = (data) => {
    return {
        type: actionTypes.SET_INWARD_VIEWDATA,
        data: data,
    };
};

export const setinwardIndirectTransaction = (data) => {
    return {
        type: actionTypes.SET_INWARD_INDIRECT_TRANSACTION,
        data: data,
    };
};

export const setInwardMaterialRating = (data) => {
    return {
        type: actionTypes.SET_INWARD_MATERIAL_RATING,
        data: data,
    };
};

export const setVendorList = (data) => {
    return {
        type: actionTypes.SET_PROCESS_VENDOR,
        data: data,
    };
};

export const changeRedirect = () => {
    return {
        type: actionTypes.CHANGE_REDIRECT,
    };
};

export const setMaterials = (data) => {
    return {
        type: actionTypes.SET_INWARD_MATERIAL,
        data: data,
    };
};

export const showModalsPopup = (modalName, data) => {
    switch (modalName) {
        case "sendInvoice":
            return {
                type: actionTypes.INWARD_INVOICE_POPUP_SHOW,
            };
        case "expenses":
            return {
                type: actionTypes.INWARD_EXPENSES_POPUP_SHOW,
                data: data,
            };
        case "rating":
            return {
                type: actionTypes.INWARD_RATING_POPUP_SHOW,
            };
        default:
            break;
    }
};

//===========================Call Api=======================

const getVendorList = () => {
    let url = vendorApi.GET_PROCESS_VENDORS;
    return axiosApi.getDataRequest(url);
};

const getMaterails = () => {
    let url = materialsServices.GET_ALL_MATERIALS_LIST;
    return axiosApi.getDataRequest(url, { isActive: false, process: "inward" });
};

// Call Multiple Api using axios
export const getInitialDataInwardCreate = () => {
    const requestOne = getVendorList();
    const requestTwo = getMaterails();
    return (dispatch) => {
        dispatch(showLoader());
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    // use/access the results
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    dispatch(setVendorList(responseOne.data.data));
                    dispatch(setMaterials(responseTwo.data.data));
                    dispatch(hideLoader());
                })
            )
            .catch((errors) => {
                console.log("error", errors);
                let message = getServerError(errors);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
                // react on errors.
            });
    };
};

//save inward

export const saveInward = (inwardId, dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = inwardApi.SAVE_INWARD;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                // console.log("result",result)
                if (response.status === 200) {
                    let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    if (dataObj.isDraft === false) {
                        //dispatch(showModalsPopup('sendInvoice'));
                        dispatch(
                            showModalsPopup("expenses", {
                                invoiceNumber: invoiceNumber,
                                inwardId: result.data[0].inwardId,
                            })
                        );
                        //history.push("/inward")
                    } else {
                        history.push("/inward");
                    }
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//inward list
// export const initInwards = (postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = inwardApi.GET_INWARDS;
//         return axiosApi.postDataRequest(url, postData)
//             .then(response => {
//                 let result = response.data.data;
//                 let arrayObj =  [];
//                     let totalRecievedQty = 0;
//                     let totalInvoicedQty = 0;
//                 if (response.status === 200) {
//                     result.forEach((element, index) => {
//                         let obj = {
//                             "inwardId": element.inwardId,
//                             "facilityId" : element.facility.facilityId,
//                             "facilityName" : element.facility.name,
//                             "invoiceNumber": element.invoiceNumber,
//                             "grandTotal": element.grandTotal,
//                             "datetime": dateCustomFormat(element.purchaseDate, 'DD MMMM YYYY hh:mm a', true),
//                             "status": element?.status?.join(', '),
//                             "canDeactivate": element.canDeactivate,
//                             "canEdit": element.canEdit,
//                             "isActive": element.isActive,
//                             "isDraft": element.isDraft,
//                             "sortingProcess": element.sortingProcess,
//                             "vendorName": element.vendor.vendorName,
//                             "isParentRow" : true
//                         }
//                         if(element.inwardMaterial.length > 1){
//                             let internalQty  = 0;
//                             let invoiceQty = 0;
//                             let materialName = '';
//                             let subMaterialName = '';

// obj["subRows"] = [];
// for (let i = 0; i < element.inwardMaterial.length; i++) {
//     totalRecievedQty += (element.inwardMaterial[i]['internalQty'] ?
//         +element.inwardMaterial[i]['internalQty']
//         :0
//     );
//     totalInvoicedQty += (element.inwardMaterial[i]['invoiceQty'] ?
//         +element.inwardMaterial[i]['invoiceQty']
//         :0
//     );
//     internalQty += element.inwardMaterial[i]['internalQty'] ?
//         +element.inwardMaterial[i]['internalQty'] : 0;
//     invoiceQty += +element.inwardMaterial[i]['invoiceQty'] ?
//         +element.inwardMaterial[i]['invoiceQty'] : 0
//     ;
//     let matlName = ""; let subMatlName = "";

//                                 if(element.inwardMaterial[i]['material'].materialNamePerFacility.length > 0){
//                                     let matlNameByFacility = element.inwardMaterial[i]['material'].materialNamePerFacility.filter(
//                                         mf => mf.facilityId === element.facility.facilityId)?.[0];
//                                     if(matlNameByFacility){
//                                         matlName = matlNameByFacility.materialAliasName
//                                     }else{
//                                         matlName = element.inwardMaterial[i]['material'].materialName
//                                     }
//                                 }else{
//                                     matlName = element.inwardMaterial[i]['material'].materialName;
//                                 }
//                                 // if(!materialName.includes(element.inwardMaterial[i]['material'].materialName)){
//                                 //     materialName += element.inwardMaterial[i]['material'].materialName +', ';
//                                 // }
//                                 if(!materialName.includes(matlName)){
//                                     materialName += matlName +', ';
//                                 }

//                                 // if(!subMaterialName.includes(element.inwardMaterial[i]['subMaterial'].subMaterialName)){
//                                 //     subMaterialName += element.inwardMaterial[i]['subMaterial'].subMaterialName +', ';
//                                 // }
//                                 if(element.inwardMaterial[i]['subMaterial'].subMaterialNamePerFacility.length > 0){
//                                     let subMatlNameByFacility = element.inwardMaterial[i]['subMaterial'].subMaterialNamePerFacility.filter(
//                                         smf => smf.facilityId === element.facility.facilityId)?.[0];
//                                     if(subMatlNameByFacility){
//                                         subMatlName = subMatlNameByFacility.subMaterialAliasName
//                                     }else{
//                                         subMatlName =  element.inwardMaterial[i]['subMaterial'].subMaterialName
//                                     }
//                                 }else{
//                                     subMatlName = element.inwardMaterial[i]['subMaterial'].subMaterialName
//                                 }
//                                 if(!subMaterialName.includes(subMatlName)){
//                                     subMaterialName += subMatlName +', ';
//                                 }

//                                 obj["subRows"].push({
//                                     internalQty : element.inwardMaterial[i]['internalQty'],
//                                     invoiceQty : element.inwardMaterial[i]['invoiceQty'],
//                                     // materialName : element.inwardMaterial[i]['material'].materialName,
//                                     // subMaterialName : element.inwardMaterial[i]['subMaterial'].subMaterialName
//                                     materialName : materialName,
//                                     subMaterialName : subMatlName
//                                 })
//                             }
//                             obj["internalQty"] = internalQty;
//                             obj["invoiceQty"] = invoiceQty;
//                             obj["materialName"] = removeLastComma(materialName);
//                             obj["subMaterialName"] = removeLastComma(subMaterialName);
//                             arrayObj.push(obj);
//                         }else{
//                             let matlName = ""; let subMatlName = "";
//                             if(element.inwardMaterial?.[0]?.['material'].materialNamePerFacility.length > 0){
//                                 let matlNameByFacility = element.inwardMaterial?.[0]?.['material'].materialNamePerFacility.filter(
//                                     mf => mf.facilityId === element.facility.facilityId)?.[0];
//                                 if(matlNameByFacility){
//                                     matlName = matlNameByFacility.materialAliasName
//                                 }else{
//                                     matlName = element.inwardMaterial?.[0]?.['material'].materialName
//                                 }
//                             }else{
//                                 matlName = element.inwardMaterial?.[0]?.['material'].materialName
//                             }

//                             if(element.inwardMaterial?.[0]?.['subMaterial'].subMaterialNamePerFacility.length > 0){
//                                 let subMatlNameByFacility = element.inwardMaterial?.[0]?.['subMaterial'].subMaterialNamePerFacility.filter(
//                                     smf => smf.facilityId === element.facility.facilityId)?.[0];
//                                 if(subMatlNameByFacility){
//                                     subMatlName = subMatlNameByFacility.subMaterialAliasName
//                                 }else{
//                                     subMatlName = element.inwardMaterial?.[0]?.['subMaterial'].subMaterialName
//                                 }
//                             }else{
//                                 subMatlName = element.inwardMaterial?.[0]?.['subMaterial'].subMaterialName
//                             }

//                             totalRecievedQty += (element.inwardMaterial?.[0]?
//                                 +element.inwardMaterial[0]['internalQty']
//                                 :0
//                             );
//                             totalInvoicedQty += (element.inwardMaterial?.[0] ?
//                                 +element.inwardMaterial[0]['invoiceQty']
//                                 :0);
//                             obj["internalQty"] =  element.inwardMaterial?.[0] ? element.inwardMaterial[0]['internalQty']:0;
//                             obj["invoiceQty"] = element.inwardMaterial?.[0] ? element.inwardMaterial[0]['invoiceQty']:0;
//                             obj["materialName"] = matlName;
//                             obj["subMaterialName"] = subMatlName;
//                             arrayObj.push(obj);
//                         }
//                     });
//                     totalRecievedQty = +(totalRecievedQty.toFixed(2));
//                     totalInvoicedQty = +(totalInvoicedQty.toFixed(2));
//                     dispatch(setInwardList(arrayObj, totalRecievedQty,totalInvoicedQty));
//                     dispatch(hideLoader());
//                 } else {
//                     ToastAlert({ type: ALERT_ERROR, msg: response.data.message });
//                     dispatch(hideLoader());
//                 }
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }

// Inward By id
export const getInwardById = (id) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = putVariableInString(inwardApi.GET_INWARD_BY_ID, id);
        return axiosApi
            .getDataRequest(url)
            .then((response) => {
                let result = response.data.data;
                if (response.status === 200 && response.data.success === true) {
                    result["inwardMatlList"] = [];
                    for (let i = 0; i < result.inwardMaterial.length; i++) {
                        let arrayItem = result.inwardMaterial[i];
                        let matlName = "";
                        let subMatlName = "";
                        if (
                            arrayItem["material"].materialNamePerFacility
                                .length > 0
                        ) {
                            let matlNameByFacility = arrayItem[
                                "material"
                            ].materialNamePerFacility.filter(
                                (mf) =>
                                    mf.facilityId === result.facility.facilityId
                            )?.[0];
                            if (matlNameByFacility) {
                                matlName = matlNameByFacility.materialAliasName;
                            } else {
                                matlName = arrayItem.material.materialName;
                            }
                        } else {
                            matlName = arrayItem.material.materialName;
                        }

                        if (
                            arrayItem["subMaterial"].subMaterialNamePerFacility
                                .length > 0
                        ) {
                            let subMatlNameByFacility = arrayItem[
                                "subMaterial"
                            ].subMaterialNamePerFacility.filter(
                                (smf) =>
                                    smf.facilityId ===
                                    result.facility.facilityId
                            )?.[0];
                            if (subMatlNameByFacility) {
                                subMatlName =
                                    subMatlNameByFacility.subMaterialAliasName;
                            } else {
                                subMatlName =
                                    arrayItem.subMaterial.subMaterialName;
                            }
                        } else {
                            subMatlName = arrayItem.subMaterial.subMaterialName;
                        }

                        let obj = {
                            inwardMaterialId: arrayItem.inwardMaterialId,
                            materialId: arrayItem.material.materialId,
                            materialName: matlName,
                            materialActive: arrayItem.material.isActive,
                            subMaterialId: arrayItem.subMaterial.subMaterialId,
                            subMaterialName: subMatlName,
                            // subMaterialActive:
                            //     arrayItem.subMaterial.gstPercentage,
                            // subMatlGst: arrayItem.subMaterial.gstPercentage,
                            subMaterialActive:
                                arrayItem.subMaterial.inwardGSTPercentage,
                            subMatlGst: arrayItem.subMaterial.inwardGSTPercentage,
                            gstAmount: +arrayItem.gstAmount,
                            internalQty: +arrayItem.internalQty,
                            dispatchQty: +arrayItem.dispatchQty,
                            bagCount: +arrayItem.bagCount,
                            price: +arrayItem.price,
                            reverseChargeDiff: +arrayItem.reverseChargeDiff,
                            discountQty: +arrayItem.discountQty,
                            discountQtyPercentage:
                                +arrayItem.discountQtyPercentage,
                            amount: +arrayItem.amount,
                            invoiceQty: +arrayItem.invoiceQty,
                            isDelete: arrayItem?.isDelete
                                ? arrayItem?.isDelete
                                : false,
                        };
                        result["inwardMatlList"].push(obj);
                    }
                    dispatch(setInwardViewdata(result));
                    dispatch(
                        setinwardIndirectTransaction(
                            result?.inwardIndirectTransaction
                        )
                    );
                    dispatch(
                        setInwardMaterialRating(result?.inwardMaterialRating)
                    );
                    dispatch(hideLoader());
                    return result;
                } else {
                    dispatch(setInwardViewdata(result));
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Indirect Expenses Type
export const getExpensesTypes = () => {
    return (dispatch) => {
        // dispatch(showLoader());
        axiosApi
            .getDataRequest(expenseApi.EXPENSES_MASTER)
            .then((response) => {
                let result = response.data.data;
                // console.log(result)
                if (response.status === 200) {
                    let arrayObj = [];

                    result.forEach((element, index) => {
                        if (element.isIndirectExpenses === true) {
                            let obj = {
                                expensesHeadId: element.expensesHeadId,
                                expensesName: element.expensesName,
                            };
                            arrayObj.push(obj);
                        }
                    });
                    dispatch(setExpansesTypes(arrayObj));
                    // dispatch(hideLoader());
                } else {
                    ToastAlert({
                        type: ALERT_WARNING,
                        msg: response.data.message,
                    });
                    // dispatch(hideLoader());
                }
            })
            .catch((error) => {
                console.log("error", error);
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Expenses data save
export const saveExpensesTypes = (inwardId, dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = expenseApi.EXPENSES;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    // let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    //dispatch(showModalsPopup('sendInvoice'));
                    dispatch(
                        showModalsPopup("expenses", { inwardId: inwardId })
                    );
                    //history.push("/inward")
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Rating data save
export const saveRatingData = (dataObj, history) => {
    return (dispatch) => {
        dispatch(showLoader());
        let url = inwardApi.INWARD_ADD_RATING;
        axiosApi
            .postDataRequest(url, dataObj)
            .then((response) => {
                let result = response.data;
                if (response.status === 200) {
                    // let invoiceNumber = dataObj.invoiceNumber;
                    ToastAlert({ type: ALERT_SUCCESS, msg: result.message });
                    dispatch(hideLoader());
                    dispatch(showModalsPopup("rating", history));
                    // dispatch(showModalsPopup('sendInvoice'));
                    //history.push("/inward")
                } else {
                    ToastAlert({
                        type: ALERT_ERROR,
                        msg: response.data.message,
                    });
                    dispatch(hideLoader());
                }
            })
            .catch((error) => {
                let message = getServerError(error);
                ToastAlert({ type: ALERT_ERROR, msg: message });
                dispatch(hideLoader());
            });
    };
};

//Change inward status
// export const updateInwardStatus = (status, id, postData) => {
//     return dispatch => {
//         dispatch(showLoader());
//         let url = putVariableInString(
//             inwardApi.CHANGE_INWARD_STATUS,id
//         );
//         axiosApi.putDataRequest(url, {isActive:status})
//             .then( response => {
//                 let result = response.data;
//                 if(response.status === 200){
//                     ToastAlert({type:ALERT_SUCCESS, msg:result.message});
//                     dispatch(initInwards(postData));
//                 }else{
//                     ToastAlert({type:ALERT_ERROR, msg:response.data.message});
//                 }
//                 dispatch(hideLoader());
//             })
//             .catch(error => {
//                 console.log('error', error)
//                 let message = getServerError(error);
//                 ToastAlert({ type: ALERT_ERROR, msg: message });
//                 dispatch(hideLoader());
//             })
//     };
// }
