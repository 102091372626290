import { Button } from 'react-bootstrap'
import React from 'react'

const InitialPlaceholder = (props) => {
  return (
    <div className="rounded bg-white p-5 text-center my-5">
      {props.placeImgSrc
        ? <img src={props.placeImgSrc} className="mb-2" width="90px" alt="placeImgSrc"/>
        : null
      }
      <br/>
        <Button variant="danger" onClick={props.clicked}>{props.btnName}</Button>
    </div>
  )
}

export default InitialPlaceholder
