import React from 'react';
import PropTypes from 'prop-types';
import { Form } from "react-bootstrap";
//import classes from './Input.css';

const input = ( props ) => {
    let inputElement = null;
    //const inputClasses = [classes.InputElement];

    // if (props.invalid && props.shouldValidate && props.touched) {
    //     inputClasses.push(classes.Invalid);
    // }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <Form.Control autocomplete="off"
                //className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} 
                maxLength={50}
                />;
            break;
        case ( 'textarea' ):
            inputElement = <Form.Control autocomplete="off" as="textarea"
                //className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} 
                />;
            break;
        case ( 'select' ):
            inputElement = (
                <Form.Control autocomplete="off" as="select"
                    //className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </Form.Control>
            );
            break;
            case ( 'inputBtn' ):
                inputElement = (
                    <Form.Check
                        //className={inputClasses.join(' ')}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                        checked = {props.checked}
                        label = {props.title}
                    />
                );
                break;
        default:
            inputElement = <Form.Control autocomplete="off"
                //className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}/>;
    }

    return (
        <div className="">
            {/* <label className={classes.Label}>{props.label}</label> */}
            {inputElement}
        </div>
    );

};

input.propTypes = {
    elementConfig: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    changed: PropTypes.func.isRequired,
}
export default input;