import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useTranslation } from 'react-i18next';

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		float: 'right',
		paddingBottom: 10
	},
}));

function TablePaginationActions(props) {
	const { t } = useTranslation();
	//const classes = useStyles1();
	const theme = useTheme();
	return (
		<div
			//className="tablepagination"
			className="text-right"

		>
			<div className="d-flex align-items-center justify-content-md-end mobilePagginationWrapper">
				<div className="mobilePagginationNum">
					<span className="mr-2" style={{ lineHeight: "2" }}>{t("Rows per page")}:</span>
					<select onChange={e => props.onchangePageSize(e)} value={props.pageSize}>
						{[10, 20, 30, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>

					{/* <span>{` ${props.pageIndex + 1}-${props.pageSize}  of ${props.count} `}</span> */}
					<span>{` ${props.pageIndex + 1} ${t("of")} ${props.count} `}</span>
				</div>
				<div className="mobilePagginationData">
					<IconButton
						onClick={() => {
							props.onFirstPage();
							if (props.onPageChange) {
								props.onPageChange(0)
							}
							props.scrollTop();
						}}
						disabled={props.canPreviousPage}
						aria-label={t("first page")}
					>
						{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
					</IconButton>
					<IconButton
						onClick={() => {
							props.onPreviousPage();
							if (props.onPageChange) {
								props.onPageChange(props.pageIndex === 0 ? props.pageIndex : props.pageIndex - 1)
							}
							props.scrollTop();
						}}
						disabled={props.canPreviousPage} aria-label={t("previous page")}>
						{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
					</IconButton>
					<IconButton
						onClick={() => {
							props.onNextpage();
							if (props.onPageChange) {
								props.onPageChange(props.pageIndex + 1)
							}
							props.scrollTop();
						}}
						disabled={props.canNextPage}
						aria-label={t("next page")}
					>
						{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
					</IconButton>
					<IconButton
						onClick={() => {
							props.onLastpage();
							if (props.onPageChange) {
								props.onPageChange(props.count-1)
							}
							props.scrollTop();
						}}
						disabled={props.canNextPage}
						aria-label={t("last page")}
					>
						{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
					</IconButton>
				</div>
			</div>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onFirstPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	onNextpage: PropTypes.func.isRequired,
	onLastpage: PropTypes.func.isRequired,
	scrollTop: PropTypes.func.isRequired,
	canPreviousPage: PropTypes.bool.isRequired,
	canNextPage: PropTypes.bool.isRequired,
};

export default TablePaginationActions

